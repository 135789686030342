/* eslint-disable no-case-declarations */
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';

import { isWithinEditableWindow } from 'common-src/models/ClientTimeLog';
import { moduleTypes } from 'common-src/models/PatientInteraction';
import { validateLabReadingUniqueness, VitalsLabsTypes } from 'common-src/models/PatientLabReading';
import { catchRoutineExamErrors } from 'common-src/models/PatientRoutineExam';
import {
  TemplateDisplayConditionType,
  TemplateItemType,
  TemplateModuleType,
  TemplateQuestionType,
} from 'common-src/models/Template';
import { isDateValid } from 'common-src/utils/dateUtils';

import { CGMEvaluationFieldIds } from 'src/pages/DocumentationIndex/modules/CGMEvaluationModule/constants';
import { VitalsAndLabsFieldIds } from 'src/pages/DocumentationIndex/modules/VitalsAndLabsModule/constants';

export const textWithMarkdown = (text, textStyle, linkedTextStyle) => {
  const htmlEls = [];

  // Match link patterns like [some link](example.com)
  //   - First group is text up to link or newline
  //   - Second is link text
  //   - Third is link reference
  //   - Fourth is newline
  const hrefRe = /(.*?)(?:\[(.*?)\]\((.*?)\)|(\n))/g;
  let hrefMatch = hrefRe.exec(text);
  let endIndex = 0;

  while (hrefMatch) {
    if (!_.isEmpty(hrefMatch[1])) {
      // If there is leading text, push it in a span
      htmlEls.push(
        <span key={`${hrefMatch[1]}-${endIndex}`} className={textStyle}>{`${hrefMatch[1]}`}</span>,
      );
    }
    if (!_.isEmpty(hrefMatch[2]) || !_.isEmpty(hrefMatch[3])) {
      // If there is a link, push it in an a tag
      htmlEls.push(
        <a key={`${hrefMatch[2]}-${endIndex}`} href={hrefMatch[3]} target="_blank" rel="noreferrer">
          <span className={linkedTextStyle}>{`${hrefMatch[2]}`}</span>
        </a>,
      );
    }
    if (!_.isEmpty(hrefMatch[4])) {
      // If match is a newline, push a break
      htmlEls.push(<br key={`${hrefMatch[4]}-${endIndex}`} />);
    }
    endIndex += hrefMatch[0].length;
    hrefMatch = hrefRe.exec(text);
  }
  const leftover = text.substring(endIndex);
  if (!_.isEmpty(leftover)) {
    // If there's anything leftover, push it in a span
    htmlEls.push(<span key={leftover} className={textStyle}>{`${leftover}`}</span>);
  }
  return htmlEls;
};

const startEndDatesTemplateTypes = [
  {
    id: moduleTypes.HoldRequest.id,
    startDateText: 'Please enter starting date for hold status',
    endDateText: 'Please enter expiration date for hold status',
  },
  {
    id: moduleTypes.CGMAssessment.id,
    startDateText: 'Start date',
    endDateText: 'End date',
  },
];

const validateStartEndDates = (templates) => {
  const templateTypesWithoutErrors = _.filter(startEndDatesTemplateTypes, (templateType) => {
    const template = _.find(templates, (t) => t.isSelected && t.templateType === templateType.id);
    if (!template) return true;

    const { items } = template;
    const questions = items.filter((item) => item.itemType === TemplateItemType.Question);

    const startingDate = questions.find((q) => q.label === templateType.startDateText)?.value;
    const endingDate = questions.find((q) => q.label === templateType.endDateText)?.value;

    if (!startingDate) return true;

    return !moment(endingDate).isBefore(moment(startingDate));
  });

  return startEndDatesTemplateTypes.length === templateTypesWithoutErrors.length;
};

const getQuestionResponse = (questionId, items) => {
  const question = items.find((item) => item.questionId === questionId);
  return question?.responses || [];
};

export const shouldDisplayTemplateItem = (displayCondition, items) => {
  if (!displayCondition) return true;

  switch (displayCondition.conditionType) {
    case TemplateDisplayConditionType.And:
      return displayCondition.conditions.every((cond) => shouldDisplayTemplateItem(cond, items));
    case TemplateDisplayConditionType.Or:
      return displayCondition.conditions.some((cond) => shouldDisplayTemplateItem(cond, items));
    case TemplateDisplayConditionType.Equals:
      const response = getQuestionResponse(displayCondition.questionId, items)[0] || {};

      if (displayCondition.intValue || displayCondition.intValue === 0) {
        return response.intValue === displayCondition.intValue;
      }
      if (displayCondition.numberValue || displayCondition.numberValue === 0) {
        return response.floatValue === displayCondition.numberValue;
      }
      if (displayCondition.stringValue) {
        return response.strValue === displayCondition.stringValue;
      }
      if (displayCondition.dateValue) {
        return (
          moment(response.dateValue).format('MM/DD/YYYY') ===
          moment(displayCondition.dateValue).format('MM/DD/YYYY')
        );
      }

      return false;
    case TemplateDisplayConditionType.Selected:
      const selectedResponse = getQuestionResponse(displayCondition.questionId, items);
      return selectedResponse.find((op) => op.optionId === displayCondition.optionId);
    default:
      throw new Error(`Unknown condition type: ${displayCondition.conditionType}`);
  }
};

/**
 * General validation function for documentation templates
 * @param templates
 * @returns {{hasMissingFields: boolean, fieldErrors: Map, validationError: null}}
 * hasMissingFields - shows whether there are missing fields or not
 * fieldErrors - map with keys equal to question ids and value the string error attached to this question
 */
export const checkTemplateRequiredFields = (templates) => {
  let hasMissingFields = false;
  let validationError = null;
  const fieldErrors = new Map();
  templates
    .filter((template) => !_.isEmpty(template))
    .forEach((template) => {
      template.items.forEach((item) => {
        const {
          questionId,
          itemType,
          questionType,
          isRequired,
          responses,
          label,
          minValue,
          maxValue,
          errorMessage,
          displayCondition,
        } = item;

        if (
          itemType !== TemplateItemType.Question ||
          !shouldDisplayTemplateItem(displayCondition, template.items)
        )
          return;

        let hasError = false;
        switch (questionType) {
          case TemplateQuestionType.Integer: {
            const value = responses[0]?.intValue;
            hasError = value && Number.isNaN(value);
            break;
          }
          case TemplateQuestionType.Currency:
          case TemplateQuestionType.Number: {
            const value = responses[0]?.floatValue;
            hasError = value && Number.isNaN(value);
            break;
          }
          case TemplateQuestionType.Date: {
            const value = responses[0]?.dateValue;
            hasError = value && !isDateValid(value);
            break;
          }
          default:
            break;
        }

        if (hasError) {
          validationError = `Value for ${label} should be a valid ${questionType}!`;
          fieldErrors.set(questionId, validationError);
        }

        if (
          maxValue &&
          [TemplateQuestionType.Text, TemplateQuestionType.TextArea].includes(questionType)
        ) {
          const value = responses[0]?.strValue;
          const hasReachedCharLimit = !value ? false : String(value).length > maxValue;

          if (hasReachedCharLimit) {
            fieldErrors.set(questionId, `Should be ${maxValue} characters at most`);
          }
        }

        if (
          [
            TemplateQuestionType.Integer,
            TemplateQuestionType.Currency,
            TemplateQuestionType.Number,
          ].includes(questionType)
        ) {
          const responseField =
            questionType === TemplateQuestionType.Integer ? 'intValue' : 'floatValue';
          const response = responses[0] || {};
          const value = response[responseField];
          const hasValue = !!value || value === 0;

          if (hasValue && (minValue || minValue === 0) && value < minValue) {
            fieldErrors.set(questionId, `Number should be more than ${minValue}`);
          }
          if (hasValue && maxValue && value > maxValue) {
            fieldErrors.set(questionId, `Number should be less than ${maxValue}`);
          }
        }

        if (!isRequired) return;

        const isFilled = !_.isEmpty(responses);
        if (!isFilled) {
          hasMissingFields = true;
          fieldErrors.set(questionId, errorMessage || 'Missing value');
        }
      });
    });

  return { hasMissingFields, validationError, fieldErrors };
};

const checkVitalsAndLabsRequiredFields = (vitalsAndLabs, labResults, lastA1c) => {
  const errors = new Map();
  let generalError = '';

  if (_.isEmpty(vitalsAndLabs)) {
    return { errors, generalError };
  }

  if ((vitalsAndLabs.weight || vitalsAndLabs.height) && !vitalsAndLabs.weightDateObserved) {
    errors.set(VitalsAndLabsFieldIds.weightDateContainer, 'Date is required');
    generalError = 'Please fill the required inputs';
  }

  if (vitalsAndLabs.hbA1c && !vitalsAndLabs.hbA1cDateObserved) {
    errors.set(VitalsAndLabsFieldIds.hbA1cDateContainer, 'Date is required');
    generalError = 'Please fill the required inputs';
  }

  if (
    lastA1c?.readingValue === vitalsAndLabs.hbA1c &&
    lastA1c?.readingDate === moment(vitalsAndLabs.hbA1cDateObserved).format('YYYY-MM-DD')
  ) {
    return { errors, generalError };
  }

  if (vitalsAndLabs.hbA1c && vitalsAndLabs.hbA1cDateObserved) {
    const isUniqueRecord = validateLabReadingUniqueness(labResults, {
      typeId: VitalsLabsTypes.HbA1c.value,
      readingDate: moment(vitalsAndLabs.hbA1cDateObserved).format('YYYY-MM-DD'),
      readingValue: vitalsAndLabs.hbA1c,
      patientReported: true,
    });

    if (!isUniqueRecord) {
      errors.set(
        VitalsAndLabsFieldIds.hbA1cContainer,
        'The lab result already exists for this date',
      );
      generalError = 'Uniqueness error: the lab result already exists for this date';
    }
  }

  return { errors, generalError };
};

const checkCgmEvaluationRequiredFields = (cgmEvaluation) => {
  const errors = new Map();
  let generalError = '';

  if (_.isEmpty(cgmEvaluation)) {
    return { errors, generalError };
  }

  if (!cgmEvaluation.startDate) {
    errors.set(CGMEvaluationFieldIds.startDateContainer, 'Start date is required');
    generalError = 'Please fill the required inputs';
  }

  if (!cgmEvaluation.endDate) {
    errors.set(CGMEvaluationFieldIds.endDateContainer, 'End date is required');
    generalError = 'Please fill the required inputs';
  }

  return { errors, generalError };
};

const checkRoutineExamsRequiredFields = (routineExams) => {
  let errors = new Map();
  let generalError = '';

  const { errors: fieldErrors, hasErrors } = catchRoutineExamErrors(routineExams);

  if (hasErrors) {
    generalError = 'Please fill the required inputs';
    errors = new Map(Object.entries(fieldErrors));
  }

  return { errors, generalError };
};

export const getDocumentFormErrors = (
  templates,
  documentation,
  serviceAt,
  timeLogs,
  awscCallIds,
  requireTimeLog,
  isEmbedded,
  requireLinkVisit,
  vitalsAndLabs,
  labResults,
  lastA1c,
  cgmEvaluation,
  routineExams,
) => {
  if (!documentation.isDraft) {
    return { generalError: 'Documentation has already been submitted!', fieldErrors: new Map() };
  }

  const selectedTemplates = _.filter(templates, 'isSelected');
  if (_.isEmpty(selectedTemplates)) {
    return { generalError: 'At least one module should be selected', fieldErrors: new Map() };
  }

  const { errors: vitalAndLabsErrors, generalError: vitalsAndLabsGeneralError } =
    checkVitalsAndLabsRequiredFields(vitalsAndLabs, labResults, lastA1c);
  if (vitalAndLabsErrors.size !== 0) {
    return { generalError: vitalsAndLabsGeneralError, fieldErrors: vitalAndLabsErrors };
  }

  const { errors: cgmEvaluationErrors, generalError: cgmEvaluationGeneralError } =
    checkCgmEvaluationRequiredFields(cgmEvaluation);
  if (cgmEvaluationErrors.size !== 0) {
    return { generalError: cgmEvaluationGeneralError, fieldErrors: cgmEvaluationErrors };
  }

  const { errors: routineExamsErrors, generalError: routineExamsGeneralError } =
    checkRoutineExamsRequiredFields(routineExams);
  if (routineExamsErrors.size !== 0) {
    return { generalError: routineExamsGeneralError, fieldErrors: routineExamsErrors };
  }

  const { validationError, hasMissingFields, fieldErrors } =
    checkTemplateRequiredFields(selectedTemplates);

  if (!_.isEmpty(fieldErrors)) {
    return { generalError: 'Please fill the required inputs', fieldErrors };
  }

  if (hasMissingFields) {
    return { generalError: 'Missing required form fields', fieldErrors: new Map() };
  }

  if (validationError) {
    return { generalError: validationError, fieldErrors: new Map() };
  }

  if (!validateStartEndDates(templates)) {
    return {
      generalError: 'Ending date should not be before starting date',
      fieldErrors: new Map(),
    };
  }

  if (isEmbedded) {
    if (_.isEmpty(serviceAt)) {
      return { generalError: "The date of care can't be empty", fieldErrors: new Map() };
    }

    if (!isWithinEditableWindow(serviceAt)) {
      return {
        generalError: "The date of care can't be set to more than 3 days in the past",
        fieldErrors: new Map(),
      };
    }

    if (requireTimeLog && ![_.isEmpty(awscCallIds), _.isEmpty(timeLogs)].includes(false)) {
      return {
        generalError:
          'Please link the conversation or indicate that no relevant conversations are shown',
        fieldErrors: new Map(),
      };
    }

    const erroredTimeLog = timeLogs.find((tl) => !tl.category || !tl.timeSpent);
    if (erroredTimeLog) {
      return {
        generalError: 'Missing required form fields',
        fieldErrors: new Map([['timeLogIndex', timeLogs.indexOf(erroredTimeLog)]]),
      };
    }

    if (isEmbedded && requireLinkVisit) {
      return 'Please link the scheduled visit';
    }
  }

  return { generalError: null, fieldErrors: new Map() };
};

export const getFinalizedDrafts = (
  templates,
  sdoh,
  cgmAlerts,
  cgmEvaluation,
  vitalsAndLabs,
  wellnessPlan,
  routineExams,
) => {
  const templatesCopy = JSON.parse(JSON.stringify(templates));
  return templatesCopy.map((template) => {
    template.items = template.items.map((item) => {
      if (item.itemType === TemplateItemType.Module) {
        let data = null;

        switch (item.moduleType) {
          case TemplateModuleType.Sdoh:
            data = !_.isEmpty(sdoh) ? sdoh : null;
            break;
          case TemplateModuleType.CGMAlerts:
            data = !_.isEmpty(cgmAlerts) ? cgmAlerts : null;
            break;
          case TemplateModuleType.CGMEvaluation:
            data = !_.isEmpty(cgmEvaluation) ? cgmEvaluation : null;
            break;
          case TemplateModuleType.VitalsAndLabs:
            data = !_.isEmpty(vitalsAndLabs) ? vitalsAndLabs : null;
            break;
          case TemplateModuleType.WellnessPlan:
            data = !_.isEmpty(wellnessPlan) ? wellnessPlan : null;
            break;
          case TemplateModuleType.RoutineExams:
            data = !_.isEmpty(routineExams) ? routineExams : null;
            break;
          default:
            data = null;
        }

        return {
          ...item,
          data,
        };
      }

      return item;
    });

    delete template.isExpanded;
    delete template.isSelected;

    return template;
  });
};

export const isFalsyValue = (value) =>
  _.isNil(value) || value === '' || value === false || Number.isNaN(value);
