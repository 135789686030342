// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditVisitPopup-module__grid___rWG6H {
  display: grid;
  padding: 25px 30px;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-template-columns: repeat(2, 1fr);
}
.EditVisitPopup-module__grid___rWG6H .EditVisitPopup-module__width100___azgXC {
  grid-column: 2 span;
}
.EditVisitPopup-module__grid___rWG6H .EditVisitPopup-module__input___lBWdw {
  width: 66%;
}
.EditVisitPopup-module__grid___rWG6H .EditVisitPopup-module__select___NP0En {
  width: 66%;
}

.EditVisitPopup-module__buttonsContainer___Ki_O1 {
  border-top: 1px solid rgba(91, 91, 91, 0.2);
  justify-content: end;
  padding: 30px;
  display: flex;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/popups/EditVisitPopup/EditVisitPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qCAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,UAAA;AAAJ;AAGE;EACE,UAAA;AADJ;;AAKA;EACE,2CAAA;EACA,oBAAA;EACA,aAAA;EACA,aAAA;EACA,SAAA;AAFF","sourcesContent":[".grid {\n  display: grid;\n  padding: 25px 30px;\n  grid-row-gap: 25px;\n  grid-column-gap: 25px;\n  grid-template-columns: repeat(2, 1fr);\n\n  .width100 {\n    grid-column: 2 span;\n  }\n\n  .input {\n    width: 66%;\n  }\n\n  .select {\n    width: 66%;\n  }\n}\n\n.buttonsContainer {\n  border-top: 1px solid rgba(91, 91, 91, 0.2);\n  justify-content: end;\n  padding: 30px;\n  display: flex;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `EditVisitPopup-module__grid___rWG6H`,
	"width100": `EditVisitPopup-module__width100___azgXC`,
	"input": `EditVisitPopup-module__input___lBWdw`,
	"select": `EditVisitPopup-module__select___NP0En`,
	"buttonsContainer": `EditVisitPopup-module__buttonsContainer___Ki_O1`
};
module.exports = ___CSS_LOADER_EXPORT___;
