import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { DatePickerNew, Input, Select } from 'common-src/components/base';
import { getClientOptions, getClients } from 'common-src/models/Client';
import {
  ALL_OWNERS,
  LiveInteractionOptions,
  TypeOptions,
} from 'common-src/models/PatientInteraction';

import Icons from 'src/assets/Icons';
import useCustomSelector from 'src/hooks/useCustomSelector';

import { DocumentationTabs } from '../../constants';
import styles from './SubHeader.module.scss';

const SubHeader = ({ filters, setFilters, selectedTab }) => {
  const clients = useCustomSelector((state) => getClients(state));

  const isDraftsTabSelected = selectedTab === Object.keys(DocumentationTabs)[1];

  return (
    <div className={[styles.container, 'gap-20'].join(' ')}>
      <Select
        id="documentation-type-select"
        options={TypeOptions}
        onChange={(type) => setFilters((filters) => ({ ...filters, type }))}
        value={filters.type}
        iconSrc={Icons.filterIcon}
        withError={false}
        isSearchable
      />
      <Select
        id="documentation-owner-select"
        options={[ALL_OWNERS, ...getClientOptions(clients, true)]}
        onChange={(owner) => setFilters((filters) => ({ ...filters, owner }))}
        value={filters.owner}
        iconSrc={Icons.filterIcon}
        withError={false}
        isSearchable
        paperStyles={{ width: 'max-content' }}
      />
      {!isDraftsTabSelected && (
        <Select
          id="documentation-live-interaction-select"
          options={LiveInteractionOptions}
          onChange={(hadLiveInteraction) =>
            setFilters((filters) => ({ ...filters, hadLiveInteraction }))
          }
          value={filters.hadLiveInteraction}
          iconSrc={Icons.filterIcon}
          withError={false}
        />
      )}
      {!isDraftsTabSelected && (
        <>
          <DatePickerNew
            id="documentation-start-date"
            value={filters.startingDate}
            maxDate={new Date()}
            placeholder="Select start date"
            onDateSelected={(date) => {
              const newDate = date ? moment(date).format('YYYY-MM-DD') : null;
              setFilters({ ...filters, startingDate: newDate });
            }}
            classNames={[styles.picker]}
          />
          <DatePickerNew
            id="documentation-end-date"
            value={filters.endingDate}
            maxDate={new Date()}
            placeholder="Select end date"
            onDateSelected={(date) => {
              const newDate = date ? moment(date).format('YYYY-MM-DD') : null;
              setFilters({ ...filters, endingDate: newDate });
            }}
            classNames={[styles.picker]}
          />
          <Input
            id="documentation-search-bar"
            onFinishEditing={(value) => setFilters({ ...filters, search: value })}
            value={filters?.search}
            placeholder="Search"
            leftContent={
              <img
                className={['search-icon', 'disable-select'].join(' ')}
                src={CommonIcons.searchIcon}
                alt="search icon"
              />
            }
            withError={false}
            debounced
          />
        </>
      )}
    </div>
  );
};

SubHeader.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  selectedTab: PropTypes.string,
};

export default SubHeader;
