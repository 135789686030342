import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, RequestHandlerScreen } from 'common-src/components/base';
import { StatusItem } from 'common-src/components/elements';
import { getRoleConfig } from 'common-src/features/auth';
import {
  ActivePatientTaskStatuses,
  getPatientTasks,
  getStatusById,
  getTaskReasonById,
  getTaskTypeById,
  PatientTaskStatus,
  TaskReason,
} from 'common-src/models/PatientTask';
import { getPatientTasksRequest, updatePatientTask } from 'common-src/models/PatientTask/actions';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import {
  ActionsRow,
  BaseTable,
  MultilineTextItem,
  TablePagination,
  TextItem,
} from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { DefaultTabInfo, TableHeaders, TasksTabs } from './constants';
import { getTabInfo } from './helpers';
import styles from './Tasks.module.scss';

const Tasks = ({ patientId }) => {
  const dispatch = useDispatch();

  const tabs = Object.keys(TasksTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  const patientTasks = useCustomSelector((state) => getPatientTasks(state, patientId));
  const actions = useCustomSelector((state) => getRoleConfig(state)?.patientChart?.sections?.tasks);

  const { isRequesting } = useRequestLoading([getPatientTasksRequest({ patientId })]);

  useInterval(() => {
    dispatch(getPatientTasksRequest({ patientId, sort_order: 'desc', limit: 5 }, {}));
  });

  useEffect(() => {
    const { tabInfo, activeTasks, completedTasks, allTasks } = getTabInfo(patientTasks);
    setTabInfo(tabInfo);

    switch (selectedTab) {
      case tabs[0]:
        setFilteredTasks(activeTasks);
        break;
      case tabs[1]:
        setFilteredTasks(completedTasks);
        break;
      default:
        setFilteredTasks(allTasks);
        break;
    }
  }, [selectedTab, patientTasks]);

  const renderStatus = (status) => {
    if (typeof status !== 'number') return '-';

    const data = getStatusById(status);
    return (
      <StatusItem
        isActive
        withArrow={false}
        text={data.label}
        textColor={data.textColor}
        backgroundColor={data.backgroundColor}
      />
    );
  };

  const getData = () =>
    filteredTasks.map((task) => {
      const isDisabled = !actions?.editTask;
      return {
        type: (
          <TextItem
            isActive
            text={getTaskTypeById(task?.typeId)?.label || ''}
            isUnderlined
            onClick={() =>
              dispatch(openModal(ModalType.EDIT_TASK, { task, isReadOnly: !actions?.editTask }))
            }
          />
        ),
        reason: <TextItem isActive text={getTaskReasonById(task?.reasonId)?.label || ''} />,
        subject: (
          <MultilineTextItem isActive splitSentences text={task.subject || ''} maxLines={2} />
        ),
        createdAt: (
          <TextItem
            isActive
            text={moment(task.createdAt).format('MM/DD/YYYY')}
            value={task.createdAt}
          />
        ),
        status: renderStatus(task.status),
        actions: (
          <ActionsRow
            options={[
              task.status === PatientTaskStatus.New.value && {
                id: 'start-button',
                text: 'Start',
                backgroundColor: '#555671B2',
                color: 'white',
                disabled: isDisabled,
                onClick: updatePatientTask(
                  task.id,
                  { status: PatientTaskStatus.InProgress.value },
                  {
                    successBlock: () => {
                      if (task.reasonId !== TaskReason.InteractionAdjustment.value) return;

                      dispatch(
                        openModal(ModalType.INTERACTION_ADJUSTMENTS, {
                          interactionId: task.data?.interactionId,
                          taskId: task.id,
                          reason: task.data?.interactionAdjustmentReason,
                        }),
                      );
                    },
                  },
                ),
              },
              ActivePatientTaskStatuses.includes(task.status) && {
                id: 'complete-button',
                text: 'Complete',
                backgroundColor: '#375844CC',
                color: 'white',
                disabled: isDisabled,
                onClick: updatePatientTask(task.id, { status: PatientTaskStatus.Completed.value }),
              },
              ActivePatientTaskStatuses.includes(task.status) && {
                id: 'cancel-button',
                text: 'Cancel',
                backgroundColor: '#F01F51B2',
                color: 'white',
                disabled: isDisabled,
                onClick: updatePatientTask(task.id, { status: PatientTaskStatus.Canceled.value }),
              },
            ]}
          />
        ),
      };
    });

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling tasks..." />;
  }

  return (
    <>
      <TabsViewNew
        classNames={['medical-tabs']}
        options={tabInfo}
        onTabSelected={setSelectedTab}
        rightContent={
          <Button
            id="new-task-button"
            text="Add Task"
            onClick={() => dispatch(openModal(ModalType.ADD_TASK, { patientId }))}
            backgroundColor={Colors.white}
            textColor={ColorsNew.darkGreen}
            iconSrc={CommonIcons.plusIcon}
            disabled={!actions?.addTask}
            classNames={[styles.button]}
          />
        }
      />
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="tasks"
        classNames={[
          'medical-table-new',
          filteredTasks?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No tasks"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'createdAt',
          label: 'Created',
          width: '10%',
          sortBy: 'value',
        }}
        paginationData={paginationData}
      />
      <TablePagination
        onChangePageHandler={setPaginationData}
        results={filteredTasks}
        selectedTab={selectedTab}
      />
    </>
  );
};

Tasks.propTypes = {
  patientId: PropTypes.number,
};

export default Tasks;
