import _ from 'lodash';
import React, { useRef, useState } from 'react';

import { FormButtons } from 'src/components/buttons';

const useFormButtons = (
  buttonLabel,
  onCancel = () => {},
  onSubmit = () => {},
  data,
  isSectionForm = false,
  sectionName,
  isSubmitButtonEnabled = true,
  skipDataEquality = false,
) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const initialData = useRef();

  const setInitialData = (data) => {
    initialData.current = JSON.parse(JSON.stringify(data));
  };

  const handleErrors = (err = {}) => {
    setErrors((prev) => ({
      ...prev,
      ...err,
    }));
  };

  const isButtonEnabled = () =>
    isSubmitButtonEnabled &&
    (skipDataEquality || !_.isEqual(initialData.current, data)) &&
    (_.isEmpty(errors) || Object.values(errors).every((e) => !e));

  const renderContent = (small, disabled, onEdit = () => {}) => (
    <FormButtons
      isLoading={isLoading}
      isEditMode={isEditMode}
      buttonLabel={buttonLabel}
      editHandler={() => {
        onEdit();
        setIsEditMode(true);
      }}
      cancelHandler={() => {
        onCancel(JSON.parse(JSON.stringify(initialData.current)));
        setIsEditMode(false);
        setErrors({});
      }}
      submitHandler={() => onSubmit()}
      isButtonEnabled={isButtonEnabled()}
      small={small}
      disabled={disabled}
      isSectionForm={isSectionForm}
      sectionName={sectionName}
    />
  );

  return {
    isEditMode,
    setIsEditMode,
    setIsLoading,
    errors,
    setErrors,
    handleErrors,
    renderContent,
    setInitialData,
    initialData: initialData.current,
  };
};

export default useFormButtons;
