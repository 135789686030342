import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Menu } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import Patient from 'common-src/models/Patient';
import {
  getPatientInteractions,
  getPatientInteractionsApiRequest,
  moduleTypes,
} from 'common-src/models/PatientInteraction';
import {
  getPatientOncehubBookings,
  getPatientOncehubBookingsRequest,
  getScheduleVisitQueryParams,
  ScheduleVisitLinks,
} from 'common-src/models/PatientOncehubBooking';
import { getPrimaryPatientPhoneNumber } from 'common-src/models/PatientPhoneNumber';

import Icons from 'src/assets/Icons';
import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useOpenWindow from 'src/hooks/useOpenWindow';

import styles from '../commonStyles.module.scss';
import { getWarningMessage } from './helpers';

const ScheduleVisitMenu = ({ patient, tooltipText }) => {
  const dispatch = useDispatch();

  const primaryPatientPhoneNumber = useCustomSelector((state) =>
    getPrimaryPatientPhoneNumber(state, patient?.id),
  );
  const scheduledVisits = useCustomSelector((state) =>
    getPatientOncehubBookings(state, patient?.id),
  );
  const patientInteractions = useCustomSelector((state) =>
    getPatientInteractions(state, patient?.id),
  );

  const { openWindow } = useOpenWindow();

  useEffect(() => {
    if (!patient?.id) return;

    dispatch(
      restRequestMultiple({
        restRequests: [
          getPatientOncehubBookingsRequest({ patientId: patient?.id }, {}),
          getPatientInteractionsApiRequest({
            patientId: patient?.id,
            isDraft: false,
            module_types: [
              moduleTypes.MNTNote.id,
              moduleTypes.NutritionConsult.id,
              moduleTypes.NutritionFollowUp.id,
              moduleTypes.BiannualVisit.id,
            ],
          }),
        ],
      }),
    );
  }, [patient?.id]);

  const renderWarningContent = (op) => {
    if (op.label !== ScheduleVisitLinks.CGMQualification.label) return null;

    return (
      <ul
        style={{ listStyleType: 'initial' }}
        className={['flex-column', 'gap-6', 'm-l-20'].join(' ')}
      >
        <li>
          Takes insulin <u style={{ textUnderlineOffset: '2px' }}>or</u>
        </li>
        <li>Has a documented history of problematic hypoglycemia</li>
      </ul>
    );
  };

  const options = Object.values(ScheduleVisitLinks).map((op) => ({
    text: op.label,
    id: op.value,
    onClick: () => {
      if (op.label === ScheduleVisitLinks.DeviceTraining.label) {
        dispatch(openModal(ModalType.ADD_TASK, { patientId: patient.id }));
        return;
      }

      const onClickHandler = () => {
        openWindow({
          url: `${op.value}${getScheduleVisitQueryParams(
            patient,
            primaryPatientPhoneNumber,
            op.linkType,
          )}`,
          target: '_blank',
        });
      };

      const warningMessage = getWarningMessage(
        op.label,
        scheduledVisits,
        patientInteractions,
        patient,
      );
      if (warningMessage) {
        dispatch(
          openModal(ModalType.WARNING, {
            message: warningMessage,
            type: 'error',
            title: 'Warning',
            content: renderWarningContent(op),
            onSubmit: () => onClickHandler(),
            submitButtonText: 'Continue',
          }),
        );

        return;
      }

      onClickHandler();
    },
  }));

  return (
    <Menu
      button={
        <img
          id="schedule-visit-button"
          className={styles.actionButton}
          src={Icons.calendarPlusIcon}
          alt="schedule-visit-icon"
        />
      }
      options={options}
      tooltipText={tooltipText}
    />
  );
};

ScheduleVisitMenu.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  tooltipText: PropTypes.string,
};

export default ScheduleVisitMenu;
