import { CommonIcons } from 'common-src/assets/Icons';
import { ColorsNew } from 'common-src/styles';

export const OncehubBookingStatuses = Object.freeze({
  today: {
    text: 'TODAY',
    value: 'today',
    label: 'TODAY',
    textColor: ColorsNew.darkGreen,
    iconSrc: CommonIcons.calendarCheckIcon,
  },
  scheduled: {
    text: 'Scheduled',
    value: 'scheduled',
    label: 'Scheduled',
    textColor: ColorsNew.darkGreen,
    iconSrc: CommonIcons.calendarCheckIcon,
  },
  rescheduled: {
    text: 'Rescheduled',
    value: 'rescheduled',
    label: 'Rescheduled',
    textColor: ColorsNew.darkGreen,
    iconSrc: CommonIcons.rescheduledIcon,
  },
  completed: {
    text: 'Completed',
    value: 'completed',
    label: 'Completed',
    textColor: ColorsNew.darkGreen,
    iconSrc: CommonIcons.callCheckIcon,
  },
  canceled: {
    text: 'Canceled',
    value: 'canceled',
    label: 'Canceled',
    textColor: '#F06C67',
    iconSrc: CommonIcons.calendarXIcon,
  },
  no_show: {
    text: 'No-Show',
    value: 'no_show',
    label: 'No-Show',
    textColor: ColorsNew.darkGreen,
    iconSrc: CommonIcons.calendarCheckIcon,
  },
  missed: {
    text: 'Missed',
    value: 'missed',
    label: 'Missed',
    textColor: '#F06C67',
    iconSrc: CommonIcons.calendarXIcon,
  },
  canceled_then_rescheduled: {
    text: 'Canceled Then Rescheduled',
    value: 'canceled_then_rescheduled',
    label: 'Canceled Then Rescheduled',
    textColor: ColorsNew.darkGreen,
    iconSrc: CommonIcons.rescheduledIcon,
  },
});

export const LinkType = Object.freeze({
  Oncehub: 'Oncehub',
  Jotform: 'Jotform',
});

// Reference: https://docs.google.com/spreadsheets/d/1IaEfZaZe5FIwmhgd9dStSmUw3yB_QMyY1374S7dX7fQ/edit#gid=853599734
export const ScheduleVisitLinks = {
  AccountabilityCoach: {
    label: 'AC Monthly Wellness Visit',
    value: 'https://go.oncehub.com/ACMonthlyVisit',
    linkType: LinkType.Oncehub,
  },
  CGMQualification: {
    label: 'CGM Consultation',
    value: 'https://go.oncehub.com/PerryCGMQualification',
    linkType: LinkType.Oncehub,
  },
  DeviceTraining: {
    label: 'Device Training',
    value: 'https://form.jotform.com/232553373723052',
    linkType: LinkType.Jotform,
  },
  EducationSession: {
    label: 'Education Session',
    value: 'https://go.oncehub.com/perryEDU',
    linkType: LinkType.Oncehub,
  },
  ClinicalInitialVisit: {
    label: 'First Visit',
    value: 'https://go.oncehub.com/perryCFV',
    linkType: LinkType.Oncehub,
  },
  InitialCoachingCall: {
    label: 'Initial Coaching Call',
    value: 'https://go.oncehub.com/InitialCoachingCall',
    linkType: LinkType.Oncehub,
  },
  Intake: {
    label: 'Intake',
    value: 'https://go.oncehub.com/perryhealth',
    linkType: LinkType.Oncehub,
  },
  MedicationReconciliation: {
    label: 'Medication Reconciliation',
    value: 'https://go.oncehub.com/perrymedreview',
    linkType: LinkType.Oncehub,
  },
  NutritionConsult: {
    label: 'MNT Consult',
    value: 'https://go.oncehub.com/perryMNT',
    linkType: LinkType.Oncehub,
  },
  MonthlyWellnessVisit: {
    label: 'Monthly Wellness Visit',
    value: 'https://go.oncehub.com/perryMWV',
    linkType: LinkType.Oncehub,
  },
  PersonalBookingPages: {
    label: 'Personal Booking Pages',
    value: 'https://go.oncehub.com/perrypersonal',
    linkType: LinkType.Oncehub,
  },
  BiannualVisit: {
    label: 'Biannual Visit',
    value: 'https://go.oncehub.com/PerryBiAnnualVisit',
    linkType: LinkType.Oncehub,
  },
  InsulinPumpTraining: {
    label: 'Insulin Pump Training',
    value: 'https://go.oncehub.com/PerryInsulinPump',
    linkType: LinkType.Oncehub,
  },
};

export const VisitType = {
  Unknown: { label: 'Unknown', value: 1, order: 0 },
  ClinicalFirstVisit: { label: 'Clinical First Visit', value: 2, order: 1 },
  InitialCoachingCall: { label: 'Initial Coaching Call', value: 3, order: 1 },
  MonthlyWellnessVisit: { label: 'Monthly Wellness Visit', value: 4, order: 1 },
  NutritionMedicationAssessment: { label: 'Nutrition Medication Assessment', value: 5, order: 1 },
  MedicationReconciliation: { label: 'Medication Reconciliation', value: 6, order: 1 },
  QuarterlyCheckIn: { label: 'Quarterly Check-in', value: 7, order: 1 },
  ClinicalMeeting: { label: 'Clinical Meeting', value: 8, order: 1 },
  CGMQualificationVisit: { label: 'CGM Qualification Visit', value: 9, order: 1 },
  BiannualVisit: { label: 'Biannual Visit', value: 10, order: 1 },
  InsulinPumpTrainingVisit: { label: 'Insulin Pump Training Visit', value: 11, order: 1 },
  BGMDeviceTrainingVisit: { label: 'BGM Device Training Visit', value: 12, order: 1 },
  CGMDeviceTrainingVisit: { label: 'CGM Device Training Visit', value: 13, order: 1 },
  DietaryNutritionalFollowUp: { label: 'Dietary Nutritional Follow Up', value: 14, order: 1 },
};
