import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { getActiveCuriePatientTasks } from 'common-src/models/PatientTask';

import Icons from 'src/assets/Icons';
import { List, RightSidebar } from 'src/components/base';
import { getRightSidebar, RightSidebarName, saveRightSidebar } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';

import CurieAICard from './CurieAICard';
import styles from './CurieAISidebar.module.scss';

const CurieAISidebar = ({ patientId }) => {
  const dispatch = useDispatch();

  const rightSidebar = useCustomSelector((state) => getRightSidebar(state, patientId));
  const activeCurieTasks = useCustomSelector((state) =>
    getActiveCuriePatientTasks(state, patientId),
  );

  return (
    <RightSidebar
      title="Curie AI"
      isOpen={!!rightSidebar?.[RightSidebarName.CURIE_AI]}
      icon={Icons.overviewIcon}
      onToggle={() => dispatch(saveRightSidebar(patientId, false, RightSidebarName.CURIE_AI))}
    >
      <List
        classNames={[styles.list]}
        items={activeCurieTasks}
        renderItem={(task) => <CurieAICard task={task} patientId={patientId} />}
        emptyText="No active CurieAI tasks"
        emptyTextClassNames={[styles.emptyText]}
      />
    </RightSidebar>
  );
};

CurieAISidebar.propTypes = {
  patientId: PropTypes.number,
};

export default CurieAISidebar;
