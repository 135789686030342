import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'common-src/components/base';
import { Colors, ColorsNew } from 'common-src/styles';

import Icons from 'src/assets/Icons';

import styles from './RightSidebar.module.scss';

const RightSidebar = ({ isOpen, onToggle, children, title, icon }) => (
  <aside className={[styles.aside, isOpen ? styles.extended : ''].join(' ')}>
    <header className={[styles.header, 'flex-row', 'primary-border-b'].join(' ')}>
      <Button
        id="collapse-button"
        classNames={[styles.collapseButton]}
        iconSrc={Icons.collapseIcon}
        onClick={onToggle}
        backgroundColor="transparent"
        textColor={ColorsNew.darkGreen}
        borderColor={Colors.lightGray}
      />
      <div className={['flex-row', 'gap-5', 'vertically-centered'].join(' ')}>
        <img className={styles.icon} src={icon} alt={`${title}-icon`} />
        <span className={['font-s-14', 'font-w-500'].join(' ')}>{title}</span>
      </div>
    </header>
    <div className={styles.content}>{children}</div>
  </aside>
);

RightSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default RightSidebar;
