import { allModelsSelector } from 'common-src/utils/selectorUtils';

export const getPatientRoutineExams = (state, patientId) =>
  allModelsSelector(state, 'PatientRoutineExam', (pre) => pre.patientId === patientId).sort(
    (a, b) => new Date(b.dateCompleted) - new Date(a.dateCompleted),
  );

export const getMostRecentPatientRoutineExams = (state, patientId) => {
  const exams = allModelsSelector(
    state,
    'PatientRoutineExam',
    (pre) => pre.patientId === patientId,
  );

  const latestExamsByType = Object.values(
    exams.reduce((acc, exam) => {
      const { type, dateCompleted } = exam;

      if (!acc[type] || new Date(dateCompleted) > new Date(acc[type].dateCompleted)) {
        acc[type] = exam;
      }

      return acc;
    }, {}),
  );

  return latestExamsByType;
};
