/* eslint-disable global-require */
export default {
  docIcon: require('./icons/docIcon.svg'),
  labsIcon: require('./icons/labsIcon.svg'),
  smartIcon: require('./icons/smartIcon.svg'),
  newDocIcon: require('./icons/newDocIcon.svg'),
  memberIcon: require('./icons/memberIcon.svg'),
  glucoseIcon: require('./icons/glucoseIcon.svg'),
  medicalIcon: require('./icons/medicalIcon.svg'),
  newLabsIcon: require('./icons/newLabsIcon.svg'),
  trendUpIcon: require('./icons/trendUpIcon.svg'),
  wellnessIcon: require('./icons/wellnessIcon.svg'),
  newSmartIcon: require('./icons/newSmartIcon.svg'),
  timeLineIcon: require('./icons/timeLineIcon.svg'),
  calendarPlusIcon: require('./icons/calendarPlusIcon.svg'),
  calendarCheckIcon: require('./icons/calendarCheckIcon.svg'),
  pillIcon: require('./icons/pillIcon.svg'),
  boldCloseIcon: require('./icons/boldCloseIcon.svg'),
  boldGearIcon: require('./icons/boldGearIcon.svg'),
  chevron: require('./icons/chevron.svg'),
  arrowDownIcon: require('./icons/arrowDownIcon.svg'),
  boldCheckIcon: require('./icons/boldCheckIcon.svg'),
  outgoingIcon: require('./icons/outgoingIcon.svg'),
  incomingIcon: require('./icons/incomingIcon.svg'),
  gearIcon: require('./icons/gearIcon.svg'),
  arrowRightIcon: require('./icons/arrowRightIcon.svg'),
  appointmentIcon: require('./icons/appointmentIcon.svg'),
  filterIcon: require('./icons/filterIcon.svg'),
  warningIcon: require('./icons/warningIcon.svg'),
  documentIcon: require('./icons/documentIcon.svg'),
  shippingIcon: require('./icons/shippingIcon.svg'),
  missedCallIcon: require('./icons/missedCallIcon.svg'),
  completeCallIcon: require('./icons/completeCallIcon.svg'),
  starIcon: require('./icons/starIcon.svg'),
  labsCircleIcon: require('./icons/labsCircleIcon.svg'),
  overviewIcon: require('./icons/overviewIcon.svg'),
  pauseIcon: require('./icons/pauseIcon.svg'),
  plusIcon: require('./icons/plusIcon.svg'),
  arrowLeftBold: require('./icons/arrowLeftBold.svg'),
  arrowRightBold: require('./icons/arrowRightBold.svg'),
  billingIcon: require('./icons/billingIcon.svg'),
  trackerIcon: require('./icons/trackerIcon.svg'),
  callIcon: require('./icons/callIcon.svg'),
  rxIcon: require('./icons/rxIcon.svg'),
  devicesIcon: require('./icons/devicesIcon.svg'),
  chatInIcon: require('./icons/chatInIcon.svg'),
  chevronDownCircleIcon: require('./icons/chevronDownCircleIcon.svg'),
  pauseCircleIcon: require('./icons/pauseCircleIcon.svg'),
  solidVideoIcon: require('./icons/solidVideoIcon.svg'),
  solidDocIcon: require('./icons/solidDocIcon.svg'),
  subjectIcon: require('./icons/subjectIcon.svg'),
  chatOutIcon: require('./icons/chatOutIcon.svg'),
  subjectCompletedIcon: require('./icons/subjectCompletedIcon.svg'),
  moneyIcon: require('./icons/moneyIcon.svg'),
  arrowPreviousIcon: require('./icons/arrowPreviousIcon.svg'),
  arrowNextIcon: require('./icons/arrowNextIcon.svg'),
  chevronDownIcon: require('./icons/chevronDown.svg'),
  teamIcon: require('./icons/teamIcon.svg'),
  vitalsIcon: require('./icons/vitalsIcon.svg'),
  vitalsTabIcon: require('./icons/vitalsTabIcon.svg'),
  wellnessOutlinedIcon: require('./icons/wellnessOutlinedIcon.svg'),
  sdohIcon: require('./icons/sdohIcon.svg'),
  sendIcon: require('./icons/sendIcon.svg'),
  collapseIcon: require('./icons/collapseIcon.svg'),
  alertErrorIconRed: require('./icons/alertErrorIconRed.svg'),
  adminNotesIcon: require('./icons/adminNotesIcon.svg'),
};
