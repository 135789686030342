import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Patient from 'common-src/models/Patient';
import { getPatientGlucoseReadingsRequest } from 'common-src/models/PatientGlucoseReading';

import { RequestHandlerScreen } from 'src/components/base';
import useInterval from 'src/hooks/useInterval';
import { BGMChart } from 'src/pages/PatientDetails/fragments/Biometrics/charts';
import {
  BGMPeriodRange,
  dayTimePeriods,
} from 'src/pages/PatientDetails/fragments/Biometrics/constants';
import { BGMFiltersHeader, BGMHeader } from 'src/pages/PatientDetails/fragments/Biometrics/headers';
import { BGMTable } from 'src/pages/PatientDetails/fragments/Biometrics/tables';

import styles from './BGMTab.module.scss';

const BGMTab = ({ patient }) => {
  const dispatch = useDispatch();

  const [selectedPeriod, setSelectedPeriod] = useState(BGMPeriodRange[1].value);
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const [dayTimePeriod, setDayTimePeriod] = useState(dayTimePeriods[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChartDrawn, setIsChartDrawn] = useState(false);

  const scrollRef = useRef();

  useInterval(() => {
    dispatch(
      getPatientGlucoseReadingsRequest(
        { patientId: patient.id, sort_order: 'desc', limit: 10 },
        {},
      ),
    );
  });

  useEffect(() => {
    setIsLoading(true);
    // Get last patient glucose reading
    dispatch(
      getPatientGlucoseReadingsRequest({ patientId: patient.id, sort_order: 'desc', limit: 1 }, {}),
    );
    dispatch(
      getPatientGlucoseReadingsRequest(
        {
          patientId: patient.id,
          start_time: moment().subtract(BGMPeriodRange[2].value, 'days').toISOString(),
          end_time: moment().toISOString(),
        },
        {
          successBlock: () => setIsLoading(false),
          errBlock: () => setIsLoading(false),
        },
      ),
    );
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getPatientGlucoseReadingsRequest(
        {
          patientId: patient.id,
          start_time: moment(endingDate).subtract(selectedPeriod, 'days').toISOString(),
          end_time: moment(endingDate).toISOString(),
        },
        {
          successBlock: () => setIsLoading(false),
          errBlock: () => setIsLoading(false),
        },
      ),
    );
  }, [endingDate, selectedPeriod]);

  useEffect(() => {
    setStartingDate(moment(endingDate).subtract(selectedPeriod, 'days').format('YYYY-MM-DD HH:mm'));
  }, [selectedPeriod]);

  const renderContent = () => {
    if (isLoading) {
      return <RequestHandlerScreen isRequesting requestingText="Pulling data..." />;
    }

    return (
      <>
        <BGMHeader patient={patient} selectedPeriod={selectedPeriod} />
        <div
          ref={scrollRef}
          className={styles.scroll}
          onScroll={() => {
            const header = document.getElementById('bgm-table-header');
            if (!header) return;

            const { top: headerTop } = header.getBoundingClientRect();
            const { top: scrollTop } = scrollRef.current.getBoundingClientRect();

            if (headerTop >= scrollTop) {
              header.classList.add(styles.sticky);
            }
          }}
        >
          <BGMChart
            patient={patient}
            selectedPeriod={selectedPeriod}
            dayTimePeriod={dayTimePeriod}
            endingDate={endingDate}
            setIsChartDrawn={setIsChartDrawn}
          />
          {isChartDrawn && (
            <BGMTable
              patientId={patient.id}
              selectedPeriod={selectedPeriod}
              endingDate={endingDate}
              dayTimePeriod={dayTimePeriod}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <BGMFiltersHeader
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        startingDate={startingDate}
        setStartingDate={setStartingDate}
        endingDate={endingDate}
        setEndingDate={setEndingDate}
        dayTimePeriod={dayTimePeriod}
        setDayTimePeriod={setDayTimePeriod}
        patient={patient}
      />
      {renderContent()}
    </>
  );
};

BGMTab.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default BGMTab;
