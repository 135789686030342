/* eslint-disable no-case-declarations */
import _ from 'lodash';

import { LinkType, VisitType } from './constants';

export const getScheduleVisitQueryParams = (patient, phone, linkType) => {
  if (_.isEmpty(patient)) {
    return '';
  }

  switch (linkType) {
    case LinkType.Oncehub:
      const name = `${patient.firstName || ''} ${patient.lastName || ''}`;
      const mobile = phone ? phone.phoneNumber?.substring(2) : '';
      const email = patient.email || '';
      const company = patient.id || '';

      return `?name=${name}&mobile=${mobile}&email=${email}&company=${company}`;
    case LinkType.Jotform:
      return `?mrn=${patient.id}`;
    default:
      return '';
  }
};

export const getVisitTypeLabel = (type) =>
  Object.values(VisitType).find((vt) => vt.value === type)?.label || 'Unknown';
