import moment from 'moment-timezone';
import React from 'react';

import { Filter } from './items';

export const getCallStatus = (scheduledCall, timeZone) => {
  const status = scheduledCall?.status;
  if (!status) return '-';
  if (!['scheduled', 'rescheduled'].includes(status)) return scheduledCall.status;

  const today = moment.tz(timeZone);
  const scheduledDate = moment.tz(scheduledCall?.scheduledAt, timeZone);
  const isScheduledForToday = today.isSame(scheduledDate, 'day');

  return isScheduledForToday ? 'today' : scheduledCall.status;
};

export const getHeaders = (types, setTypes) => [
  { column: 'scheduledTime', label: 'Scheduled Time', width: '14%', sortBy: 'date' },
  { column: 'createdTime', label: 'Created Time', width: '10%', sortBy: 'date' },
  {
    column: 'type',
    label: 'Type',
    filter: <Filter types={types} setTypes={setTypes} />,
    width: '16%',
  },
  { column: 'reason', label: 'Visit Reason', width: '11%' },
  { column: 'assigned', label: 'Assigned', width: '10%' },
  { column: 'status', label: 'Status', width: '10%' },
  { column: 'actions', label: 'Actions', width: '29%' },
];
