import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, RequestHandlerScreen } from 'common-src/components/base';
import { StatusItem } from 'common-src/components/elements';
import { getRoleConfig } from 'common-src/features/auth';
import {
  activeStatusesIds,
  getPatientSdoh,
  getPatientSdohRequest,
  getSdohName,
  getStatusById,
  SdohStatus,
  updateSdoh,
} from 'common-src/models/PatientSdoh';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import {
  ActionsRow,
  BaseTable,
  MultilineTextItem,
  TablePagination,
  TextItem,
} from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { DefaultTabInfo, SdohTabs, TableHeaders } from './constants';
import { getTabInfo } from './helpers';
import styles from './Sdoh.module.scss';

const Sdoh = ({ patientId }) => {
  const dispatch = useDispatch();

  const tabs = Object.keys(SdohTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [filteredSdohRecords, setFilteredSdohRecords] = useState([]);
  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  const patientSdoh = useCustomSelector((state) => getPatientSdoh(state, patientId));
  const actions = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.sections?.sdohList,
  );

  const { isRequesting } = useRequestLoading([getPatientSdohRequest({ patientId })]);

  useInterval(() => {
    dispatch(getPatientSdohRequest({ patientId, sort_order: 'desc', limit: 5 }, {}));
  });

  useEffect(() => {
    const { tabInfo, activeSdohRecords, resolvedSdohRecords, allSdohRecords } =
      getTabInfo(patientSdoh);
    setTabInfo(tabInfo);

    switch (selectedTab) {
      case tabs[0]:
        setFilteredSdohRecords(activeSdohRecords);
        break;
      case tabs[1]:
        setFilteredSdohRecords(resolvedSdohRecords);
        break;
      default:
        setFilteredSdohRecords(allSdohRecords);
        break;
    }
  }, [selectedTab, patientSdoh]);

  const renderStatus = (status) => {
    if (!status) return '-';

    const data = getStatusById(status);
    return (
      <StatusItem
        isActive
        withArrow={false}
        text={data.label}
        textColor={data.textColor}
        backgroundColor={data.backgroundColor}
      />
    );
  };

  const getData = () =>
    filteredSdohRecords.map((sdoh) => {
      const isDisabled = !actions?.editSdoh;
      return {
        socialDeterminant: (
          <TextItem
            isActive
            text={getSdohName(sdoh.sdohCodeId)}
            value={getSdohName(sdoh.sdohCodeId)}
            isUnderlined
            onClick={() =>
              dispatch(
                openModal(ModalType.SDOH, { sdoh, patientId, isReadOnly: !actions?.editSdoh }),
              )
            }
          />
        ),
        note: <MultilineTextItem isActive text={sdoh.note || ''} value={sdoh.note} maxLines={3} />,
        createdAt: (
          <TextItem
            isActive
            text={moment(sdoh.createdAt).format('MM/DD/YYYY')}
            value={sdoh.createdAt}
          />
        ),
        updatedAt: (
          <TextItem
            isActive
            text={moment(sdoh.updatedAt).format('MM/DD/YYYY')}
            value={sdoh.updatedAt}
          />
        ),
        status: renderStatus(sdoh.status),
        actions: (
          <ActionsRow
            options={[
              activeStatusesIds.includes(sdoh.status) && {
                id: 'edit-button',
                text: 'Edit',
                backgroundColor: '#555671B2',
                color: 'white',
                disabled: isDisabled,
                onClick: () =>
                  dispatch(
                    openModal(ModalType.SDOH, { sdoh, patientId, isReadOnly: !actions?.editSdoh }),
                  ),
              },
              [SdohStatus.Active.id, SdohStatus.Addressed.id].includes(sdoh.status) && {
                id: 'flag-for-review-button',
                text: 'Flag for Review',
                backgroundColor: ColorsNew.mediumDarkRed,
                color: 'white',
                disabled: isDisabled,
                onClick: updateSdoh(sdoh.id, { status: SdohStatus.ToAddress.value }),
              },
              sdoh.status === SdohStatus.ToAddress.id && {
                id: 'mark-as-addressing-button',
                text: 'Mark as Addressing',
                backgroundColor: ColorsNew.baseOrange,
                color: 'white',
                disabled: isDisabled,
                onClick: updateSdoh(sdoh.id, { status: SdohStatus.Addressing.value }),
              },
              sdoh.status === SdohStatus.Addressing.id && {
                id: 'mark-as-addressed-button',
                text: 'Mark as Addressed',
                backgroundColor: '#9D97C7',
                color: 'white',
                disabled: isDisabled,
                onClick: updateSdoh(sdoh.id, { status: SdohStatus.Addressed.value }),
              },
              activeStatusesIds.includes(sdoh.status) && {
                id: 'resolve-button',
                text: 'Resolve',
                backgroundColor: '#375844CC',
                color: 'white',
                disabled: isDisabled,
                onClick: updateSdoh(sdoh.id, { status: SdohStatus.Resolved.value }),
              },
            ]}
          />
        ),
      };
    });

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling SDOH records..." />;
  }

  return (
    <>
      <TabsViewNew
        classNames={['medical-tabs']}
        options={tabInfo}
        onTabSelected={setSelectedTab}
        rightContent={
          <Button
            id="new-sdoh-button"
            text="Add SDOH"
            onClick={() => dispatch(openModal(ModalType.SDOH, { patientId }))}
            backgroundColor={Colors.white}
            textColor={ColorsNew.darkGreen}
            iconSrc={CommonIcons.plusIcon}
            disabled={!actions?.addSdoh}
            classNames={[styles.button]}
          />
        }
      />
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="sdoh"
        classNames={[
          'medical-table-new',
          filteredSdohRecords?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No SDOH records"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'updatedAt',
          label: 'Last Updated',
          width: '13%',
          sortBy: 'value',
        }}
        paginationData={paginationData}
      />
      <TablePagination
        onChangePageHandler={setPaginationData}
        results={filteredSdohRecords}
        selectedTab={selectedTab}
      />
    </>
  );
};

Sdoh.propTypes = {
  patientId: PropTypes.number,
};

export default Sdoh;
