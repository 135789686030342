import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { RequestHandlerScreen } from 'common-src/components/base';
import { getCurrentUserId } from 'common-src/features/auth';
import { getIsClientsCached } from 'common-src/features/cache';
import {
  AwscInitiationMethod,
  getAwscCallRequest,
  getClientAwscCall,
} from 'common-src/models/AwscCall';
import { formatDuration } from 'common-src/utils/dateUtils';

import { BaseTable, TablePagination, TextItem } from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { TableHeaders } from './constants';

const CallsScreen = () => {
  const dispatch = useDispatch();

  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  const clientId = useCustomSelector((state) => getCurrentUserId(state));
  const awscCalls = useCustomSelector((state) => getClientAwscCall(state, clientId));
  const isClientsCached = useCustomSelector((state) => getIsClientsCached(state));

  const { isRequesting } = useRequestLoading(
    [getAwscCallRequest({ clientId })],
    () => {},
    !isClientsCached,
  );

  useInterval(() => {
    dispatch(getAwscCallRequest({ clientId, sort_order: 'desc', limit: 5 }, {}));
  });

  if (isRequesting || !isClientsCached) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling calls..." />;
  }

  const getInitiationMethod = (initiationMethod) =>
    Object.values(AwscInitiationMethod).includes(initiationMethod)
      ? _.startCase(initiationMethod.toLowerCase())
      : '-';

  const getData = () =>
    awscCalls.map((call) => ({
      clientName: <TextItem isActive text={call.client ? call.client.getName() : '-'} />,
      patientName: <TextItem isActive text={call.patient ? call.patient.getName() : '-'} />,
      initiationMethod: <TextItem isActive text={getInitiationMethod(call.initiationMethod)} />,
      disposition: <TextItem isActive text={call.disposition ?? '-'} />,
      agentInteractionDuration: (
        <TextItem isActive text={formatDuration(call.agentInteractionDuration)} />
      ),
      afterContactWorkDuration: (
        <TextItem isActive text={formatDuration(call.afterContactWorkDuration)} />
      ),
      isLinked: <TextItem isActive text={call.interactionId ? 'Yes' : 'No'} />,
      createdAt: (
        <TextItem
          isActive
          text={moment(call.createdAt).format('MM/DD/YYYY')}
          value={call.createdAt}
        />
      ),
    }));

  return (
    <>
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="calls"
        classNames={[
          'medical-table-new',
          awscCalls?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No calls"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'createdAt',
          label: 'Created At',
          width: '10%',
          sortBy: 'value',
        }}
        paginationData={paginationData}
      />
      <TablePagination onChangePageHandler={setPaginationData} results={awscCalls} />
    </>
  );
};

export default CallsScreen;
