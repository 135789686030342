import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  ALL_OWNERS,
  LiveInteractionOptions,
  TypeOptions,
} from 'common-src/models/PatientInteraction';

import {
  getPatientDocumentationTabInfo,
  savePatientDocumentationTabInfo,
} from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';

export const initialFiltersState = {
  startingDate: null,
  endingDate: null,
  type: TypeOptions[0],
  owner: ALL_OWNERS,
  search: '',
  hadLiveInteraction: LiveInteractionOptions[0],
};

const useDocumentationTabInfo = (patientId) => {
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({});
  const [selectedDocument, setSelectedDocument] = useState({});

  const prevTabInfo = useRef({});

  const tabInfo = useCustomSelector((state) => getPatientDocumentationTabInfo(state, patientId));

  const updatePrevTabInfo = (field, value) => {
    prevTabInfo.current = {
      ...prevTabInfo.current,
      [field]: value,
    };
  };

  useEffect(() => {
    const info = tabInfo || {};
    return () => {
      dispatch(savePatientDocumentationTabInfo(patientId, { ...info, ...prevTabInfo.current }));
    };
  }, []);

  useEffect(() => {
    if (!tabInfo) {
      setFilters(initialFiltersState);
      return;
    }

    setSelectedDocument(tabInfo?.document || {});
    setFilters(!_.isEmpty(tabInfo?.filters) ? tabInfo.filters : initialFiltersState);
  }, [tabInfo]);

  useEffect(() => {
    updatePrevTabInfo('filters', filters);
  }, [filters]);

  return {
    updatePrevTabInfo,
    filters,
    setFilters,
    selectedDocument,
    setSelectedDocument,
  };
};

export default useDocumentationTabInfo;
