import PropTypes from 'prop-types';
import React from 'react';

import { Accordion } from 'common-src/components/base';
import Patient from 'common-src/models/Patient';

import { Addresses, Notes } from 'src/components/forms';

import { ContactInformation, PatientDemographics, Preferences } from './forms';
import styles from './Profile.module.scss';

const Profile = ({ patient }) => (
  <article className={styles.article}>
    <div className={['flex-column', 'gap-16'].join(' ')}>
      <Accordion
        title="Patient Demographics"
        isDefaultExpanded
        content={<PatientDemographics patient={patient} />}
      />
      <Accordion
        title="Contact Information"
        isDefaultExpanded
        content={<ContactInformation patient={patient} />}
      />
      <Accordion title="Addresses" isDefaultExpanded content={<Addresses patient={patient} />} />
      <Accordion
        title="Preferences"
        isDefaultExpanded
        content={<Preferences patient={patient} />}
      />
    </div>
    <div>
      <Accordion
        title="Administrative notes"
        isDefaultExpanded
        content={<Notes patient={patient} />}
      />
    </div>
  </article>
);

Profile.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default Profile;
