import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Accordion, Button, RequestHandlerScreen, Select } from 'common-src/components/base';
import { StatusItem } from 'common-src/components/elements';
import { getRoleConfig } from 'common-src/features/auth';
import {
  ACTIVE_STATUSES,
  ALL_PLAN_STATUSES,
  getPatientCoverages,
  getPatientCoveragesRequest,
  getPlanStatus,
  planStatusOptions,
} from 'common-src/models/PatientCoverage';
import {
  getCoverageHierarchyRequest,
  getMostRecentHierarchy,
  getPatientCoverageHierarchies,
} from 'common-src/models/PatientCoverageHierarchy';
import { Colors, ColorsNew } from 'common-src/styles';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import Icons from 'src/assets/Icons';
import { ModalType } from 'src/components/base/ModalGroup';
import { ActionsButton } from 'src/components/buttons';
import { Addresses } from 'src/components/forms';
import { BaseTable, TablePagination, TextItem } from 'src/components/table';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { DefaultTabInfo, KnownPlansTabs } from './constants';
import { PatientDemographics } from './forms';
import { getTabInfo, getTableHeaders } from './helpers';
import styles from './KnownPlans.module.scss';

const KnownPlans = ({ patientId }) => {
  const dispatch = useDispatch();

  const tabs = Object.keys(KnownPlansTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });
  const [filters, setFilters] = useState({ status: ALL_PLAN_STATUSES });

  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const plans = useCustomSelector((state) => getPatientCoverages(state, patientId));
  const coverageHierarchies = useCustomSelector((state) =>
    getPatientCoverageHierarchies(state, patientId),
  );
  const actions =
    useCustomSelector((state) => getRoleConfig(state)?.patientChart?.sections?.knownPlans) || {};

  const { showAlert, AlertType } = useAlert();

  const { isRequesting } = useRequestLoading([
    getPatientCoveragesRequest(
      { patientId },
      {
        successBlock: () => dispatch(getCoverageHierarchyRequest({ patientId })),
      },
    ),
  ]);

  useEffect(() => {
    if (isRequesting) return;

    dispatch(getCoverageHierarchyRequest({ patientId, sort_order: 'desc' }, {}));
  }, [plans]);

  useInterval(() => {
    dispatch(getPatientCoveragesRequest({ patientId, sort_order: 'desc', limit: 5 }, {}));
  });

  useEffect(() => {
    const { tabInfo, allPlans, activePlans, inactivePlans } = getTabInfo(plans, filters);
    setTabInfo(tabInfo);

    let plansToShow;
    switch (selectedTab) {
      case tabs[0]:
        plansToShow = activePlans;
        break;
      case tabs[1]:
        plansToShow = inactivePlans;
        break;
      case tabs[2]:
        plansToShow = allPlans;
        break;
      default:
        plansToShow = [];
    }

    setFilteredPlans(plansToShow);
  }, [selectedTab, plans, coverageHierarchies, filters]);

  const renderStatus = (statusId) => {
    const data = getPlanStatus(statusId);
    return <StatusItem isActive withArrow={false} text={data.label} backgroundColor={data.color} />;
  };

  const renderHeader = () => (
    <div className={styles.header}>
      <div className={styles.accordionContainer}>
        <Accordion
          title="Patient demographics"
          content={<PatientDemographics patient={patient} />}
        />
        <Accordion title="Addresses" content={<Addresses patient={patient} />} />
      </div>
      <Button
        id="new-plan-button"
        text="Add Plan"
        onClick={() => dispatch(openModal(ModalType.ADD_PLAN, { patientId }))}
        backgroundColor={Colors.white}
        textColor={ColorsNew.darkGreen}
        iconSrc={CommonIcons.plusIcon}
        classNames={[styles.button]}
      />
    </div>
  );

  const getData = () =>
    filteredPlans.map((plan) => {
      const coverageHierarchiesById = coverageHierarchies.filter(
        (h) => h.patientCoverageId === plan.id,
      );

      const coverageHierarchy = getMostRecentHierarchy(coverageHierarchiesById, plan);

      return {
        payorName: (
          <TextItem
            isActive
            text={plan.payorName}
            isUnderlined={ACTIVE_STATUSES.includes(plan.status)}
            onClick={() => {
              if (!ACTIVE_STATUSES.includes(plan.status)) return;
              dispatch(openModal(ModalType.EDIT_PLAN, { planId: plan.id }));
            }}
          />
        ),
        planName: <TextItem isActive text={plan.planName || '-'} />,
        hierarchy: <TextItem isActive text={coverageHierarchy?.hierarchy ?? '-'} />,
        memberId: <TextItem isActive text={plan.memberId || '-'} />,
        startDate: (
          <TextItem
            isActive
            text={plan.startDate ? moment(plan.startDate).format('MM/DD/YYYY') : '-'}
            value={plan.startDate}
          />
        ),
        endDate: (
          <TextItem
            isActive
            text={plan.endDate ? moment(plan.endDate).format('MM/DD/YYYY') : '-'}
            value={plan.endDate}
          />
        ),
        status: renderStatus(plan.status),
        actions: (
          <ActionsButton
            id={plan.id}
            options={_.compact([
              {
                id: 'view-or-edit-plan',
                text: 'View or Edit Plan',
                iconSrc: Icons.boldCloseIcon,
                onClick: () => dispatch(openModal(ModalType.EDIT_PLAN, { planId: plan.id })),
              },
            ])}
            disabled={!ACTIVE_STATUSES.includes(plan.status)}
          />
        ),
      };
    });

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling known plans..." />;
  }

  return (
    <div className={styles.container}>
      {renderHeader()}
      <TabsViewNew
        classNames={['medical-tabs']}
        options={tabInfo}
        onTabSelected={setSelectedTab}
        showZero
        tabClassNames={['flex-none']}
        rightContent={
          <Select
            id="status-select"
            classNames={[styles.select]}
            options={planStatusOptions}
            onChange={(status) => setFilters((prev) => ({ ...prev, status }))}
            value={filters.status}
            size="small"
          />
        }
      />
      <BaseTable
        headers={getTableHeaders(selectedTab === 'ACTIVE')}
        data={getData()}
        name="known-plans"
        emptyText="No known plans"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'startDate',
          label: 'Start Date',
          width: '10%',
          sortBy: 'value',
        }}
        paginationData={paginationData}
      />
      <TablePagination
        onChangePageHandler={setPaginationData}
        results={filteredPlans}
        selectedTab={selectedTab}
      />
    </div>
  );
};

KnownPlans.propTypes = {
  patientId: PropTypes.number,
};

export default KnownPlans;
