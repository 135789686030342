import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useEventsLinking from 'src/hooks/useEventsLinking';
import { FormHeader } from 'src/pages/DocumentationIndex/components';

import Conversations from './Conversations';
import styles from './LinkedConversationsModule.module.scss';
import ManualTimeAddition from './ManualTimeAddition';

const LinkedConversationsModule = ({
  patientId,
  timeLogs,
  onTimeLogChange,
  errors,
  hasRelevantConversations,
  setHasRelevantConversations,
  awscCallIds,
  setAwscCallIds,
  classNames = [],
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { awscCalls, clients } = useEventsLinking(patientId);

  const renderContent = () => {
    if (!isExpanded) return null;

    return (
      <div className={styles.wrapper}>
        <Conversations
          hasRelevantConversations={hasRelevantConversations}
          setHasRelevantConversations={setHasRelevantConversations}
          awscCallIds={awscCallIds}
          setAwscCallIds={setAwscCallIds}
          awscCalls={awscCalls}
          clients={clients}
        />
        <ManualTimeAddition timeLogs={timeLogs} onTimeLogChange={onTimeLogChange} errors={errors} />
      </div>
    );
  };

  return (
    <div className={[...classNames].join(' ')}>
      <FormHeader
        title="Linked conversations"
        isExpanded={isExpanded}
        onClick={() => setIsExpanded((prev) => !prev)}
      />
      {renderContent()}
    </div>
  );
};

LinkedConversationsModule.propTypes = {
  patientId: PropTypes.number,
  timeLogs: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      timeSpent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  onTimeLogChange: PropTypes.func,
  errors: PropTypes.object,
  hasRelevantConversations: PropTypes.bool,
  setHasRelevantConversations: PropTypes.func,
  awscCallIds: PropTypes.array,
  setAwscCallIds: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default LinkedConversationsModule;
