import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteOrmItems } from 'common-src/features/rest';
import {
  finalizePatientInteraction,
  updatePatientDraftApiRequest,
} from 'common-src/models/PatientInteraction';
import { getLastA1cReading, getPatientLabResults } from 'common-src/models/PatientLabReading';
import PatientRoutineExam, {
  getMostRecentPatientRoutineExams,
  getRoutineExamsModuleData,
} from 'common-src/models/PatientRoutineExam';

import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';
import {
  getIsDraftDeleting,
  getIsDraftSaving,
  saveIsDraftSaving,
  saveNoteData,
  savePatientDocumentationTabInfo,
  savePatientNote,
} from 'src/features/tabsState';
import { getTimer } from 'src/features/timeTracker';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import {
  checkTemplateRequiredFields,
  getDocumentFormErrors,
  getFinalizedDrafts,
} from 'src/pages/DocumentationIndex/helpers';

import useAutoSave from './useAutoSave';

const AUTO_SAVE_TIMEOUT_LENGTH = 5000;

const useSaveDocumentation = ({
  handleError,
  templates,
  documentationToEdit,
  patientId,
  primaryModuleTypeId,
  serviceAt,
  timeLogs = [],
  awscCallIds = [],
  hasRelevantConversations = false,
  isEncounterNotScheduled = false,
  onClose,
  isEmbedded,
  linkedVisitId,
  sdoh = {},
  vitalsAndLabs = {},
  wellnessPlan = {},
  cgmAlerts = {},
  cgmEvaluation = {},
  routineExams = [],
}) => {
  const dispatch = useDispatch();

  const [isDraftCompleting, setIsDraftCompleting] = useState(false);
  const [hasDetectedChanges, setHasDetectedChanges] = useState(null);

  const prevNoteData = useRef({});

  const timer = useCustomSelector((state) => getTimer(state, patientId));
  const labResults = useCustomSelector((state) => getPatientLabResults(state, patientId));
  const lastA1c = useCustomSelector((state) => getLastA1cReading(state, patientId));
  const isDraftSaving = useCustomSelector((state) => getIsDraftSaving(state, patientId));
  const isDraftDeleting = useCustomSelector((state) => getIsDraftDeleting(state, patientId));
  const patientRoutineExams = useCustomSelector((state) =>
    getMostRecentPatientRoutineExams(state, patientId),
  );

  const { showAlert, AlertType } = useAlert();

  const isAddendum = !!documentationToEdit.parentInteractionId;
  const selectedTemplates = templates.filter((t) => t.isSelected);

  useEffect(() => {
    if (!documentationToEdit?.id || !patientId) return;

    const noteData = {
      moduleTypes: documentationToEdit.moduleTypes,
      draftData: documentationToEdit.draftData,
      serviceAt: documentationToEdit.serviceAt,
      primaryModuleTypeId: documentationToEdit.primaryModuleTypeId,
    };

    dispatch(savePatientNote(patientId, documentationToEdit.id));
    dispatch(saveNoteData(patientId, noteData));
    prevNoteData.current = noteData;
  }, [documentationToEdit?.id, patientId]);

  useEffect(() => {
    if (!hasDetectedChanges) return;

    const noteData = {
      moduleTypes: selectedTemplates.map((t) => t.templateType),
      draftData: {
        drafts: selectedTemplates,
        timeLogs,
        sdoh,
        vitalsAndLabs,
        wellnessPlan,
        cgmAlerts,
        cgmEvaluation,
        routineExams,
      },
      serviceAt: moment(serviceAt).utc().toISOString(),
      primaryModuleTypeId: primaryModuleTypeId || null,
    };

    dispatch(saveNoteData(patientId, noteData));
    prevNoteData.current = noteData;
  }, [hasDetectedChanges]);

  const saveDocumentation = () => {
    if (
      isDraftSaving ||
      isDraftCompleting ||
      isDraftDeleting ||
      !documentationToEdit.isDraft ||
      !documentationToEdit
    )
      return;

    const { validationError } = checkTemplateRequiredFields(selectedTemplates);
    if (validationError) {
      handleError({ generalError: validationError, fieldErrors: new Map() });
      return;
    }

    dispatch(saveIsDraftSaving(patientId, true));
    dispatch(
      updatePatientDraftApiRequest(
        documentationToEdit.id,
        {
          moduleTypes: selectedTemplates.map((t) => t.templateType),
          draftData: {
            drafts: selectedTemplates,
            timeLogs,
            sdoh,
            vitalsAndLabs,
            wellnessPlan,
            cgmAlerts,
            cgmEvaluation,
            routineExams,
          },
          serviceAt: moment(serviceAt).utc().toISOString(),
          primaryModuleTypeId,
          duration: timer,
        },
        {
          successBlock: () => dispatch(saveIsDraftSaving(patientId, false)),
          errorBlock: (error) => {
            dispatch(saveIsDraftSaving(patientId, false));
            handleError({ generalError: error, fieldErrors: new Map() });
          },
        },
      ),
    );
  };

  const { savedAt } = useAutoSave(AUTO_SAVE_TIMEOUT_LENGTH, hasDetectedChanges, () =>
    saveDocumentation(),
  );

  const completeDocumentation = (hadLiveInteraction, timer) => {
    const routineExamsModuleData = getRoutineExamsModuleData(routineExams, patientRoutineExams);

    setIsDraftCompleting(true);
    dispatch(
      finalizePatientInteraction(
        documentationToEdit.id,
        {
          serviceAt: moment(serviceAt).utc().toISOString(),
          primaryModuleTypeId,
          draftData: {
            drafts: getFinalizedDrafts(
              selectedTemplates,
              sdoh,
              cgmAlerts,
              cgmEvaluation,
              vitalsAndLabs,
              wellnessPlan,
              routineExamsModuleData,
            ),
          },
          awscCallIds,
          manualTime: timeLogs.map((tl) => ({ ...tl, timeSpent: Number(tl.timeSpent) * 60 })),
          missingRegalEvents: hasRelevantConversations,
          hadLiveInteraction,
          duration: timer,
          oncehubBookingId: linkedVisitId,
        },
        {
          successBlock: () => {
            setIsDraftCompleting(false);
            dispatch(savePatientDocumentationTabInfo(patientId, {}));

            showAlert(AlertType.Success, '', 'Success. Documentation Saved!');
            setTimeout(() => {
              if (onClose) {
                onClose();
              } else {
                window.close();
              }

              if (routineExamsModuleData.deletions.length) {
                console.log(routineExamsModuleData.deletions);
                deleteOrmItems(PatientRoutineExam.modelName, (exam) =>
                  routineExamsModuleData.deletions.includes(exam.id),
                );
              }
            }, 500);
          },
          errorBlock: (error) => {
            setIsDraftCompleting(false);
            handleError({ generalError: error, fieldErrors: new Map() });
          },
        },
      ),
    );
  };

  const handleButtonClick = () => {
    if (isDraftCompleting) return;

    const { generalError, fieldErrors } = getDocumentFormErrors(
      templates,
      documentationToEdit,
      serviceAt,
      timeLogs,
      awscCallIds,
      !isAddendum && !hasRelevantConversations,
      isEmbedded,
      !isEncounterNotScheduled && !linkedVisitId,
      vitalsAndLabs,
      labResults,
      lastA1c,
      cgmEvaluation,
      routineExams,
    );

    if (generalError || !_.isEmpty(fieldErrors)) {
      const questionId = fieldErrors.entries().next().value?.[0];
      const element = document.querySelectorAll(`[id*="${questionId}"]`)[0];
      setTimeout(() => {
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      }, 0);

      handleError({ generalError, fieldErrors });
      return;
    }

    handleError({ generalError: null, fieldErrors: new Map() });

    if (isEmbedded) {
      dispatch(
        openModal(ModalType.NOTE_CONFIRMATION, {
          onSubmit: (hadLiveInteraction) => completeDocumentation(hadLiveInteraction, timer),
        }),
      );

      return;
    }

    completeDocumentation();
  };

  return {
    timer,
    savedAt: savedAt || documentationToEdit.updatedAt,
    isDraftCompleting,
    isDraftSaving,
    hasDetectedChanges,
    handleButtonClick,
    setHasDetectedChanges,
  };
};

export default useSaveDocumentation;
