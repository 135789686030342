import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { RoutineExams } from 'src/components/forms';

import styles from './RoutineExamsModule.module.scss';

const RoutineExamsModule = ({
  patientId,
  errors,
  routineExams,
  setRoutineExams,
  setHasDetectedChanges,
}) => {
  useEffect(() => {
    setHasDetectedChanges(new Date());
  }, []);

  return (
    <div className={styles.container}>
      <p className={['font-w-600', 'font-s-16', 'm-b-14'].join(' ')}>Routine Diabetes Exams</p>
      <RoutineExams
        patientId={patientId}
        isEditMode
        formData={routineExams}
        setFormData={(data) => {
          setRoutineExams(data);
          setHasDetectedChanges(new Date());
        }}
        errors={Object.fromEntries(errors)}
        setErrors={() => {}}
        isEmbedded
      />
    </div>
  );
};

RoutineExamsModule.propTypes = {
  patientId: PropTypes.number,
  errors: PropTypes.object,
  routineExams: PropTypes.array,
  setRoutineExams: PropTypes.func,
  setHasDetectedChanges: PropTypes.func,
};

export default RoutineExamsModule;
