import _ from 'lodash';

import { deleteOrmItem } from 'common-src/features/rest';
import PatientRoutineExam, { extractRoutineExam } from 'common-src/models/PatientRoutineExam';
import {
  createPatientRoutineExam,
  deletePatientRoutineExam,
  updatePatientRoutineExam,
} from 'common-src/models/PatientRoutineExam/actions';

export const getRoutineExamRequests = (formData, exams) => {
  const requests = [];

  if (!Array.isArray(exams) || !Array.isArray(formData)) return requests;

  const oldExams = exams.map((exam) => extractRoutineExam(exam));

  if (_.isEqual(formData, oldExams)) {
    return requests; // No changes detected, return empty array
  }

  formData.forEach((exam) => {
    const examExists = oldExams.find(({ id }) => id === exam.id);

    if (!examExists) {
      // Add new exam
      requests.push(createPatientRoutineExam(exam));
      return;
    }

    if (!_.isEqual(exam, examExists)) {
      // Edit exam
      requests.push(updatePatientRoutineExam(exam.id, exam));
    }
  });

  // Delete exams that are not in the new formData
  oldExams.forEach(({ id }) => {
    const examExists = formData.find((exam) => exam.id === id);

    if (!examExists) {
      requests.push(
        deletePatientRoutineExam(id, {
          successBlock: () => {
            deleteOrmItem(PatientRoutineExam.modelName, id);
          },
        }),
      );
    }
  });

  return requests;
};
