import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import Patient from 'common-src/models/Patient';

import Icons from 'src/assets/Icons';
import { RightSidebar } from 'src/components/base';
import Notes from 'src/components/forms/Notes/Notes';
import { getRightSidebar, RightSidebarName, saveRightSidebar } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';

const AdminNotesSidebar = ({ patient }) => {
  const dispatch = useDispatch();

  const rightSidebar = useCustomSelector((state) => getRightSidebar(state, patient.id));

  return (
    <RightSidebar
      title="Administrative Notes"
      icon={Icons.adminNotesIcon}
      isOpen={!!rightSidebar?.[RightSidebarName.ADMIN_NOTES]}
      onToggle={() => dispatch(saveRightSidebar(patient.id, false, RightSidebarName.ADMIN_NOTES))}
    >
      <div className="p-16">
        <Notes patient={patient} />
      </div>
    </RightSidebar>
  );
};

AdminNotesSidebar.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default AdminNotesSidebar;
