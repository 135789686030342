import PropTypes from 'prop-types';
import React from 'react';

import { TemplateModuleType } from 'common-src/models/Template';

import CGMAlertsModule from '../CGMAlertsModule/CGMAlertsModule';
import CGMEvaluationModule from '../CGMEvaluationModule';
import RoutineExamsModule from '../RoutineExamsModule';
import SdohModule from '../SdohModule';
import VitalsAndLabsModule from '../VitalsAndLabsModule';
import WellnessPlanModule from '../WellnessPlanModule';

const DocumentModule = (props) => {
  switch (props.moduleType) {
    case TemplateModuleType.Sdoh:
      return <SdohModule {...props} />;
    case TemplateModuleType.CGMAlerts:
      return <CGMAlertsModule {...props} />;
    case TemplateModuleType.CGMEvaluation:
      return <CGMEvaluationModule {...props} />;
    case TemplateModuleType.VitalsAndLabs:
      return <VitalsAndLabsModule {...props} />;
    case TemplateModuleType.WellnessPlan:
      return <WellnessPlanModule {...props} />;
    case TemplateModuleType.RoutineExams:
      return <RoutineExamsModule {...props} />;
    default:
      return null;
  }
};

DocumentModule.propTypes = {
  moduleType: PropTypes.string,
};

export default DocumentModule;
