import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input, Select } from 'common-src/components/base';
import { getRoleConfig } from 'common-src/features/auth';
import { getActiveClients, getClientOptions } from 'common-src/models/Client';
import PatientOncehubBooking, {
  OncehubBookingStatuses,
  updateOncehubBooking,
  VisitType,
} from 'common-src/models/PatientOncehubBooking';

import { InfoItem } from 'src/components/elements';
import { TimeItem } from 'src/components/table';
import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import { getScheduledCallType } from 'src/pages/PatientDetails/fragments/ScheduledCalls/helpers';
import { getCallStatus } from 'src/pages/PatientDetails/fragments/ScheduledCalls/ScheduledCallsList/helpers';
import BasePopup from 'src/popups/BasePopup';

import styles from './EditVisitPopup.module.scss';

const statusOptions = [OncehubBookingStatuses.missed, OncehubBookingStatuses.completed];

const EditVisitPopup = ({ onClose, timeZone, scheduledCall }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({});

  const clients = useCustomSelector((state) => getActiveClients(state));
  const canEditVisitType =
    useCustomSelector(
      (state) => getRoleConfig(state)?.patientChart?.sections?.scheduledCalls?.canEditVisitType,
    ) || false;

  const { renderButtons } = usePopup();

  const clientOptions = useMemo(() => getClientOptions(clients, true), [clients]);

  useEffect(() => {
    if (_.isEmpty(scheduledCall)) return;
    setData(_.pick(scheduledCall, ['clientId', 'status', 'visitReason', 'visitType']));
  }, []);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();

    dispatch(
      updateOncehubBooking(scheduledCall.id, data, {
        successBlock: () => {
          successCallback('Visit edited!');
          onClose(true);
        },
        errorBlock: (err) => errorCallback(err),
      }),
    );
  };

  const getStatus = () => {
    if (getCallStatus(scheduledCall, timeZone) === OncehubBookingStatuses.today.value) {
      return OncehubBookingStatuses.today;
    }

    return Object.values(OncehubBookingStatuses).find((op) => op.value === data.status);
  };

  const renderVisitType = () => {
    if (canEditVisitType) {
      return (
        <Select
          id="type-select"
          label="Type"
          options={Object.values(VisitType)}
          onChange={(op) => setData((prev) => ({ ...prev, visitType: op.value }))}
          value={Object.values(VisitType).find((vt) => vt.value === data.visitType)}
          withError={false}
          size="small"
          paperHeight={200}
        />
      );
    }

    return (
      <InfoItem title="Type" content={getScheduledCallType(scheduledCall)} textId="type" isSmall />
    );
  };

  return (
    <BasePopup id="edit-visit" open onClose={onClose} title="Edit Visit">
      <div className={styles.grid}>
        <InfoItem
          title="Scheduled Time"
          content={
            <TimeItem
              date={scheduledCall.scheduledAt}
              duration={scheduledCall.durationMinutes}
              timeZone={timeZone}
              id="scheduled-time"
            />
          }
          isSmall
        />
        <InfoItem
          title="Created Time"
          content={
            <TimeItem date={scheduledCall.createdAt} timeZone={timeZone} id="created-time" />
          }
          isSmall
        />
        {renderVisitType()}
        <Select
          id="status"
          label="Status"
          options={statusOptions}
          onChange={(op) => setData((prev) => ({ ...prev, status: op.value }))}
          value={getStatus()}
          withError={false}
          size="small"
          paperHeight={200}
        />
        <div className={styles.width100}>
          <Input
            id="visit-reason"
            classNames={[styles.input]}
            label="Visit Reason"
            value={data.visitReason || ''}
            onTextChange={(text) => setData((prev) => ({ ...prev, visitReason: text }))}
            size="small"
          />
          <Select
            id="assigned"
            label="Assigned"
            classNames={[styles.select]}
            options={clientOptions}
            isSearchable
            onChange={(op) => setData((prev) => ({ ...prev, clientId: op.value }))}
            value={clientOptions.find((op) => op.value === data.clientId)}
            withError={false}
            size="small"
            paperHeight={200}
          />
        </div>
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: !_.isEqual(_.pick(scheduledCall, ['clientId', 'status']), data),
        submitButtonText: 'Save and Close',
      })}
    </BasePopup>
  );
};

EditVisitPopup.propTypes = {
  onClose: PropTypes.func,
  timeZone: PropTypes.string,
  scheduledCall: PropTypes.exact(PatientOncehubBooking.schema),
};

export default EditVisitPopup;
