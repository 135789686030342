import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, RequestHandlerScreen } from 'common-src/components/base';
import {
  getPatientSurgicalHistory,
  getPatientSurgicalHistoryRequest,
  makeSurgicalHistoryInactive,
} from 'common-src/models/PatientSurgicalHistory';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import {
  ActionsRow,
  BaseTable,
  MultilineTextItem,
  TablePagination,
  TextItem,
} from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { DefaultTabInfo, SurgicalHistoryTabs, TableHeaders } from './constants';
import { getTabInfo } from './helpers';
import styles from './Surgeries.module.scss';

const Surgeries = ({ patientId, actionsAllowed }) => {
  const dispatch = useDispatch();

  const surgicalHistory = useCustomSelector((state) => getPatientSurgicalHistory(state, patientId));

  const tabs = Object.keys(SurgicalHistoryTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [filteredSurgicalHistory, setFilteredSurgicalHistory] = useState([]);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);

  const { isRequesting } = useRequestLoading([getPatientSurgicalHistoryRequest({ patientId })]);

  useInterval(() => {
    dispatch(getPatientSurgicalHistoryRequest({ patientId, sort_order: 'desc', limit: 5 }, {}));
  });

  useEffect(() => {
    const { tabInfo, activeSurgicalHistory, inactiveSurgicalHistory } = getTabInfo(surgicalHistory);
    setTabInfo(tabInfo);
    setFilteredSurgicalHistory(
      selectedTab === tabs[0] ? activeSurgicalHistory : inactiveSurgicalHistory,
    );
  }, [selectedTab, surgicalHistory]);

  const showAddSurgicalHistoryPopup = () =>
    dispatch(openModal(ModalType.SURGICAL_HISTORY, { patientId }));

  const getData = () =>
    filteredSurgicalHistory.map((surgicalHistory) => {
      const isDisabled =
        (surgicalHistory.activeTill && moment(surgicalHistory.activeTill).isBefore(moment.now())) ||
        !actionsAllowed;

      return {
        procedure: (
          <TextItem
            isActive
            text={surgicalHistory?.procedure || '-'}
            value={surgicalHistory?.procedure}
          />
        ),
        comments: (
          <MultilineTextItem isActive text={surgicalHistory?.comments || '-'} maxLines={2} />
        ),
        dateOfSurgery: (
          <TextItem
            isActive
            text={
              surgicalHistory?.dateOfSurgery
                ? moment(surgicalHistory?.dateOfSurgery).format('MM/DD/YYYY')
                : '-'
            }
            value={
              surgicalHistory?.dateOfSurgery
                ? moment(surgicalHistory?.dateOfSurgery).format('MM/DD/YYYY')
                : '-'
            }
          />
        ),
        createdAt: (
          <TextItem
            isActive
            text={moment(surgicalHistory.createdAt).format('MM/DD/YYYY')}
            value={surgicalHistory.createdAt}
          />
        ),
        actions: (
          <ActionsRow
            options={[
              {
                id: 'edit-button',
                text: 'Edit',
                backgroundColor: '#555671B2',
                color: 'white',
                disabled: isDisabled,
                onClick: openModal(ModalType.SURGICAL_HISTORY, { patientId, surgicalHistory }),
              },
              {
                id: 'remove-button',
                text: 'Remove',
                backgroundColor: '#F01F51B2',
                color: 'white',
                disabled: isDisabled,
                onClick: makeSurgicalHistoryInactive(surgicalHistory.id, {}),
                showWarning: true,
              },
            ]}
          />
        ),
      };
    });

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling surgeries..." />;
  }

  return (
    <>
      <TabsViewNew
        classNames={['medical-tabs']}
        options={tabInfo}
        onTabSelected={setSelectedTab}
        rightContent={
          <Button
            id="new-surgical-history-button"
            text="Add Surgery"
            onClick={() => showAddSurgicalHistoryPopup()}
            backgroundColor={Colors.white}
            textColor={ColorsNew.darkGreen}
            iconSrc={CommonIcons.plusIcon}
            disabled={!actionsAllowed}
            classNames={[styles.button]}
          />
        }
      />
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="surgical-history"
        classNames={[
          'medical-table-new',
          filteredSurgicalHistory?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No surgical history"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'createdAt',
          label: 'Date Added',
          width: '13%',
          sortBy: 'value',
        }}
        paginationData={paginationData}
      />
      <TablePagination
        onChangePageHandler={setPaginationData}
        results={filteredSurgicalHistory}
        selectedTab={selectedTab}
      />
    </>
  );
};

Surgeries.propTypes = {
  patientId: PropTypes.number.isRequired,
  actionsAllowed: PropTypes.bool,
};

export default Surgeries;
