import Icons from 'web/src/assets/Icons';

import { moduleTypes } from './protocolModules';

export const ALL_OWNERS = Object.freeze({
  value: -1,
  label: 'All Owners',
});

export const ALL_DOCUMENT_TYPES = Object.freeze({
  id: -1,
  title: 'All Document Types',
});

export const DocumentationType = [
  ALL_DOCUMENT_TYPES,
  ...Object.values(moduleTypes)
    .filter((moduleType) => moduleType.id !== moduleTypes.Addendum.id)
    .map(({ id, title }) => ({ id, title })),
];

export const TypeOptions = Object.values(DocumentationType).map((type) => ({
  label: type.title,
  value: type.id,
}));

export const LiveInteractionSort = Object.freeze({
  ALL_INTERACTIONS: {
    label: 'All interactions',
    value: null,
  },
  HAD_LIVE_INTERACTION: {
    label: 'Live interaction',
    value: true,
  },
  HAD_NO_LIVE_INTERACTION: {
    label: 'No live interaction',
    value: false,
  },
});

export const LiveInteractionOptions = Object.values(LiveInteractionSort).map((option) => ({
  label: option.label,
  value: option.value,
}));

export const QuestionnaireDocuments = [
  moduleTypes.PerryHealthAssessment.id,
  moduleTypes.PerryMotivationQuestionnaire.id,
  moduleTypes.PHQ9GAD7Assessment.id,
];

export const NotTrackableDocumentations = [moduleTypes.CloseRequest.id, moduleTypes.HoldRequest.id];

export const InteractionReason = Object.freeze({
  CaseManagement: {
    id: 1,
    label: 'Case management',
  },
  ChartReview: {
    id: 2,
    label: 'Chart review',
  },
  Message: {
    id: 3,
    label: 'Message',
  },
  Administrative: {
    id: 4,
    label: 'Administrative',
  },
  FirstVisit: {
    id: 5,
    label: 'First visit',
  },
  BiAnnualCheckup: {
    id: 6,
    label: 'Bi-annual checkup',
  },
  Psychotherapy: {
    id: 7,
    label: 'Psychotherapy',
  },
  RemoteMonitoring: {
    id: 8,
    label: 'Remote monitoring',
  },
  OtherEvaluationAndManagement: {
    id: 9,
    label: 'Other eval & management',
  },
  OtherNonBillable: {
    id: 10,
    label: 'Other (non-billable)',
  },
  CoachIntroduction: {
    id: 11,
    label: 'Coach introduction',
  },
  OtherRpm: {
    id: 12,
    label: 'Other RPM',
  },
  ExternalConsult: {
    id: 13,
    label: 'External consult',
  },
  InternalConsult: {
    id: 14,
    label: 'Internal consult',
  },
  RecordsRequest: {
    id: 15,
    label: 'Records request',
  },
  EmergencyContactOutreach: {
    id: 16,
    label: 'Emergency contact outreach',
  },
  EmsEscalation: {
    id: 17,
    label: 'EMS Escalation',
  },
  Intake: {
    id: 18,
    label: 'Intake',
  },
});

export const InteractionType = Object.freeze({
  Telehealth: {
    id: 1,
    label: 'Telehealth',
    iconSrc: Icons.solidVideoIcon,
    isActive: true,
  },
  PhoneCall: {
    id: 2,
    label: 'Phone call',
    iconSrc: Icons.callIcon,
    isActive: true,
  },
  CaseManagement: {
    id: 3,
    label: 'Case management',
    iconSrc: Icons.trackerIcon,
    reasonId: InteractionReason.CaseManagement.id,
    isActive: true,
  },
  ChartReview: {
    id: 4,
    label: 'Chart review',
    iconSrc: Icons.solidDocIcon,
    reasonId: InteractionReason.ChartReview.id,
    isActive: true,
  },
  CareCoordination: {
    id: 5,
    label: 'Care coordination',
    iconSrc: Icons.subjectIcon,
    isActive: true,
  },
  Message: {
    id: 6,
    label: 'Message',
    iconSrc: Icons.chatOutIcon,
    reasonId: InteractionReason.Message.id,
    isActive: true,
  },
  Administrative: {
    id: 7,
    label: 'Administrative',
    iconSrc: Icons.subjectCompletedIcon,
    reasonId: InteractionReason.Administrative.id,
    isActive: true,
  },
  Enrollment: {
    id: 8,
    label: 'Intake',
    iconSrc: Icons.solidDocIcon,
    reasonId: InteractionReason.Intake.id,
    isActive: true,
  },
});

export const InteractionStatus = Object.freeze({
  Active: 1,
  Paused: 2,
  Finalized: 3,
});

export const InteractionBillingCategory = Object.freeze({
  EMNew: {
    value: 'EM New',
    label: 'EM New',
  },
  EMExisting: {
    value: 'EM Existing',
    label: 'EM Existing',
  },
  RPM: {
    value: 'RPM',
    label: 'RPM',
  },
  CCM: {
    value: 'CCM',
    label: 'CCM',
  },
  CoachIntro: {
    value: '0591T',
    label: '0591T',
  },
  NotBillable: {
    value: 'Not Billable',
    label: 'Not Billable',
  },
});
