import { ORM } from 'redux-orm';

import AwscCall from 'common-src/models/AwscCall';
import CgmAuthRequest from 'common-src/models/CgmAuthRequest/CgmAuthRequest';
import Client from 'common-src/models/Client/Client';
import FeatureFlag from 'common-src/models/FeatureFlag';
import Icd10Code from 'common-src/models/Icd10Code/Icd10Code';
import Organization from 'common-src/models/Organization/Organization';
import Patient from 'common-src/models/Patient/Patient';
import PatientAllergy from 'common-src/models/PatientAllergy';
import PatientBillingExemption from 'common-src/models/PatientBillingExemption';
import PatientCareProvider from 'common-src/models/PatientCareProvider/PatientCareProvider';
import PatientCgmGlucoseReading from 'common-src/models/PatientCgmGlucoseReading/PatientCgmGlucoseReading';
import PatientCgmOrder from 'common-src/models/PatientCgmOrder/PatientCgmOrder';
import PatientCoverage from 'common-src/models/PatientCoverage';
import PatientCoverageHierarchy from 'common-src/models/PatientCoverageHierarchy';
import PatientDevice from 'common-src/models/PatientDevice/PatientDevice';
import PatientEmergencyContact from 'common-src/models/PatientEmergencyContact';
import PatientEncounter from 'common-src/models/PatientEncounter';
import PatientFamilyHistory from 'common-src/models/PatientFamilyHistory';
import PatientFulfillmentOrder from 'common-src/models/PatientFulfillmentOrder/PatientFulfillmentOrder';
import PatientGlucoseReading from 'common-src/models/PatientGlucoseReading/PatientGlucoseReading';
import PatientHistoryEvent from 'common-src/models/PatientHistoryEvent';
import PatientInteraction from 'common-src/models/PatientInteraction/PatientInteraction';
import PatientLabReading from 'common-src/models/PatientLabReading';
import PatientMedicalProblem from 'common-src/models/PatientMedicalProblem/PatientMedicalProblem';
import PatientMedication from 'common-src/models/PatientMedication/PatientMedication';
import PatientOncehubBooking from 'common-src/models/PatientOncehubBooking';
import PatientPhoneNumber from 'common-src/models/PatientPhoneNumber/PatientPhoneNumber';
import PatientPreferredPharmacy from 'common-src/models/PatientPreferredPharmacy/PatientPreferredPharmacy';
import PatientRoutineExam from 'common-src/models/PatientRoutineExam';
import PatientSdoh from 'common-src/models/PatientSdoh/PatientSdoh';
import PatientSmartGoal from 'common-src/models/PatientSmartGoal/PatientSmartGoal';
import PatientSupplement from 'common-src/models/PatientSupplement';
import PatientSurgicalHistory from 'common-src/models/PatientSurgicalHistory';
import PatientTask from 'common-src/models/PatientTask';
import PatientVital from 'common-src/models/PatientVital';
import PatientWellnessPlan from 'common-src/models/PatientWellnessPlan';
import PreAuthorization from 'common-src/models/PreAuthorization';
import SdohCode from 'common-src/models/SdohCode';
import Setting from 'common-src/models/Setting/Setting';
import Template from 'common-src/models/Template/Template';

export const ormModelClasses = [
  AwscCall,
  CgmAuthRequest,
  Client,
  FeatureFlag,
  Organization,
  Patient,
  PatientDevice,
  PatientFulfillmentOrder,
  PatientGlucoseReading,
  PatientHistoryEvent,
  PatientInteraction,
  PatientMedication,
  PatientPhoneNumber,
  PatientLabReading,
  PatientAllergy,
  PatientSupplement,
  PatientCareProvider,
  PatientEmergencyContact,
  PatientSmartGoal,
  PatientOncehubBooking,
  PatientCgmGlucoseReading,
  PatientMedicalProblem,
  Icd10Code,
  PatientFamilyHistory,
  PatientEncounter,
  PatientSurgicalHistory,
  PatientCgmOrder,
  PatientCoverageHierarchy,
  PatientCoverage,
  PatientBillingExemption,
  PreAuthorization,
  PatientVital,
  SdohCode,
  PatientSdoh,
  PatientTask,
  Setting,
  PatientWellnessPlan,
  PatientPreferredPharmacy,
  Template,
  PatientRoutineExam,
];

const orm = new ORM({ stateSelector: (state) => state.orm });
orm.register(...ormModelClasses);

export default orm;
