import { HttpMethods, restRequest } from 'common-src/features/rest';

export const getPatientRoutineExamsRequest = (query, params) =>
  restRequest({
    uri: 'patientRoutineExams',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createPatientRoutineExam = (body, params) =>
  restRequest({
    uri: 'patientRoutineExams',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updatePatientRoutineExam = (id, body, params) =>
  restRequest({
    uri: `patientRoutineExams/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const deletePatientRoutineExam = (id, params) =>
  restRequest({
    uri: `patientRoutineExams/${id}`,
    method: HttpMethods.Delete,
    ...params,
  });
