import _ from 'lodash';
import { useEffect, useState } from 'react';

import { apiRequest } from 'common-src/features/rest';

import useInterval from 'src/hooks/useInterval';

const useEventsLinking = (patientId) => {
  const [awscCalls, setAwscCalls] = useState([]);
  const [clients, setClients] = useState([]);

  useInterval(() => {
    apiRequest({
      endpoint: `awscCall/latest/${patientId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        setAwscCalls(data?.AwscCall || []);

        const uniqueClients = _.uniqBy([...clients, ...(data?.Client || [])], 'id');
        setClients(uniqueClients);
      });
  }, 20 * 1000); // INFO: poll awsc calls on every 20 seconds

  useEffect(() => {
    apiRequest({
      endpoint: `awscCall/latest/${patientId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        setAwscCalls(data?.AwscCall || []);
        setClients(data?.Client || []);
      });
  }, []);

  return { awscCalls, clients };
};

export default useEventsLinking;
