import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientRoutineExam extends Model {
  static modelName = 'PatientRoutineExam';

  static fields = {
    id: attr(),
    type: attr(),
    status: attr(),
    dateCompleted: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    createdBy: fk('Client', 'patientRoutineExamCreator'),
    updatedBy: fk('Client', 'patientRoutineExamChanger'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'patientRoutineExams',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      type: PropTypes.string,
      status: PropTypes.string,
      dateCompleted: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      createdBy: PropTypes.number,
      updatedBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientRoutineExam;
