import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CircleLoader } from 'common-src/components/base';
import {
  getDocumentationById,
  getDocumentationNote,
  getPatientInteractionAddendums,
  getSortedDocuments,
  moduleTypes,
} from 'common-src/models/PatientInteraction';
import { ColorsNew } from 'common-src/styles';

import { saveNoteData, saveNoteScroll, savePatientNote } from 'src/features/tabsState';
import { clearTimer } from 'src/features/timeTracker';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useDocumentationConfig from 'src/hooks/useDocumentationConfig';
import useDocumentationErrors from 'src/hooks/useDocumentationErrors';
import useForceSubmitNote from 'src/hooks/useForceSubmitNote';
import useNoteScroll from 'src/hooks/useNoteScroll';
import useSaveDocumentation from 'src/hooks/useSaveDocumentation';
import {
  DocumentForm,
  FormHeader,
  NoteHeader,
  SectionFooter,
} from 'src/pages/DocumentationIndex/components';
import { CommonModules } from 'src/pages/DocumentationIndex/modules';

import styles from '../commonStyles.module.scss';

const AddendumForm = ({ patientId, documentationId, isRequestRunning }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isNoteExpanded, setIsNoteExpanded] = useState(true);

  const onClose = () => {
    navigate(`/dashboard-client/patients/details/${patientId}/documentation`);
    dispatch(savePatientNote(patientId, null));
    dispatch(saveNoteData(patientId, {}));
    dispatch(clearTimer(patientId));
    setTimeout(() => dispatch(saveNoteScroll(patientId, 0)), 100);
  };

  const { scrollRef, scrollLoading, onScrollHanlder } = useNoteScroll(patientId);
  const { handleError, errors } = useDocumentationErrors();
  const {
    patient,
    documentationToEdit: addendumToEdit,
    templates,
    primaryModuleTypeId,
    setTemplates,
    timeLogs,
    onTimeLogChange,
    serviceAt,
    setServiceAt,
    awscCallIds,
    setAwscCallIds,
    hasRelevantConversations,
    setHasRelevantConversations,
    isEncounterNotScheduled,
    setIsEncounterNotScheduled,
    linkedVisitId,
    setLinkedVisitId,
  } = useDocumentationConfig(patientId, documentationId, moduleTypes.Addendum.id);
  const {
    timer,
    savedAt,
    isDraftCompleting,
    isDraftSaving,
    setHasDetectedChanges,
    handleButtonClick,
  } = useSaveDocumentation({
    handleError,
    templates,
    documentationToEdit: addendumToEdit,
    patientId,
    primaryModuleTypeId,
    serviceAt,
    timeLogs,
    awscCallIds,
    hasRelevantConversations,
    isEncounterNotScheduled,
    onClose,
    isEmbedded: true,
    linkedVisitId,
  });

  const documentation = useCustomSelector((state) =>
    getDocumentationById(state, addendumToEdit.parentInteractionId),
  );
  const addendums = useCustomSelector((state) =>
    getSortedDocuments(
      getPatientInteractionAddendums(state, addendumToEdit.parentInteractionId),
      false,
    ),
  );

  useForceSubmitNote(isRequestRunning);

  const timeLogChangeHandler = (index, field, value) => {
    onTimeLogChange(index, field, value);
    setHasDetectedChanges(new Date());
  };

  const renderDocumentForm = () => (
    <>
      <DocumentForm
        templates={templates}
        setTemplates={setTemplates}
        setHasDetectedChanges={setHasDetectedChanges}
        errors={errors}
        classNames={[scrollLoading ? 'invisible' : 'visible']}
        handleError={handleError}
        timeLogs={timeLogs}
        onTimeLogChange={timeLogChangeHandler}
        patientId={patientId}
      />
      <CommonModules
        templates={templates}
        patientId={patientId}
        timeLogs={timeLogs}
        onTimeLogChange={onTimeLogChange}
        errors={errors}
        hasRelevantConversations={hasRelevantConversations}
        setHasRelevantConversations={setHasRelevantConversations}
        awscCallIds={awscCallIds}
        setAwscCallIds={setAwscCallIds}
        classNames={[scrollLoading ? 'invisible' : 'visible']}
        isEncounterNotScheduled={isEncounterNotScheduled}
        setIsEncounterNotScheduled={setIsEncounterNotScheduled}
        linkedVisitId={linkedVisitId}
        setLinkedVisitId={setLinkedVisitId}
        documentationToEdit={addendumToEdit}
        setHasDetectedChanges={setHasDetectedChanges}
      />
    </>
  );

  const renderContent = () => (
    <>
      <CircleLoader
        classNames={['absolute', scrollLoading ? 'visible' : 'invisible']}
        strokeWidth={4}
        circleRadius={24}
        color={ColorsNew.darkGreen}
        style={{ bottom: '50%', left: '50%' }}
      />
      <NoteHeader
        documentation={addendumToEdit}
        patient={patient}
        serviceAt={serviceAt}
        setServiceAt={setServiceAt}
        classNames={[scrollLoading ? 'invisible' : 'visible']}
      />
      <FormHeader
        title="Original Note Text"
        isExpanded={isNoteExpanded}
        onClick={() => setIsNoteExpanded((prev) => !prev)}
        classNames={[scrollLoading ? 'invisible' : 'visible']}
      />
      {isNoteExpanded && (
        <p className={[styles.note, scrollLoading ? 'invisible' : 'visible'].join(' ')}>
          {getDocumentationNote(documentation?.note, addendums)}
        </p>
      )}
      {renderDocumentForm()}
    </>
  );

  return (
    <>
      <section>
        <div className={[styles.section, styles.isEmbedded].join(' ')}>
          <div ref={scrollRef} className={styles.formWrapper} onScroll={onScrollHanlder}>
            {renderContent()}
          </div>
        </div>
      </section>
      <SectionFooter
        isLoading={isDraftCompleting}
        isDraftSaving={isDraftSaving}
        draftSavedAt={savedAt}
        handleButtonClick={handleButtonClick}
        isEmbedded
        onClose={onClose}
        handleError={handleError}
        patientId={patientId}
        timer={timer}
      />
    </>
  );
};

AddendumForm.propTypes = {
  patientId: PropTypes.number,
  documentationId: PropTypes.string,
  isRequestRunning: PropTypes.bool,
};

export default AddendumForm;
