/* eslint-disable react/no-unescaped-entities */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { FormSelect, Input } from 'common-src/components/base';
import { TimeLogTypeOptions } from 'common-src/models/ClientTimeLog';
import { ColorsNew } from 'common-src/styles';

import { AddButton, RemoveButton } from 'src/components/buttons';

import styles from './ManualTimeAddition.module.scss';

const ManualTimeAddition = ({ timeLogs, onTimeLogChange, errors }) => {
  const renderTimeLogs = () => {
    const categoriesToExclude = _.map(timeLogs, 'category');
    return timeLogs.map((timeLog, index) => {
      const { timeSpent, category } = timeLog;
      const moreThanAnHour = timeSpent > 60;
      const id = `time-log-${index}`;

      const type = Object.values(TimeLogTypeOptions).find((op) => op.value === category) || null;

      const isErrored =
        typeof errors?.get('timeLogIndex') === 'number' &&
        Number(errors.get('timeLogIndex')) === index;

      const hasMissingTimeSpent =
        isErrored && (typeof timeSpent !== 'number' || Number(timeSpent) < 1);
      const hasMissingType = isErrored && _.isEmpty(type);

      return (
        <div id="timeLogIndex" key={id} className="col-90 flex-row m-t-16 gap-30">
          <Input
            id={`${id}-input-time`}
            classNames={['flex-1']}
            warningText={moreThanAnHour ? 'Warning: Please review time log for accuracy' : ''}
            label="Time Spent (minutes)"
            placeholder="Enter time in minutes"
            value={timeSpent}
            onTextChange={(value) => onTimeLogChange(index, 'timeSpent', value)}
            type="number"
            minValue={1}
            required
            withError={hasMissingTimeSpent}
            errorText={hasMissingTimeSpent ? 'Missing time' : null}
            size="small"
            requiredColor={ColorsNew.mediumDarkRed}
          />
          <FormSelect
            id={`${id}-select-type`}
            classNames={['flex-1']}
            label="Interaction Type"
            options={TimeLogTypeOptions.filter(
              (option) => !categoriesToExclude.includes(option.value),
            )}
            onChange={(op) => onTimeLogChange(index, 'category', op.value)}
            value={type}
            placeholder="Select type"
            required
            paperHeight={300}
            position="absolute"
            withError={hasMissingType}
            errorText={hasMissingType ? 'Missing type' : null}
          />
          <RemoveButton
            id={`${id}-remove-button`}
            onClick={() => onTimeLogChange(index)}
            classNames={[styles.removeButton]}
          />
        </div>
      );
    });
  };

  return (
    <>
      <p
        className={[styles.header, 'font-s-16', 'font-w-700', 'primary-border-b', 'm-t-22'].join(
          ' ',
        )}
      >
        Manual Time Addition
      </p>
      <p className={[styles.subHeader, 'font-w-500', 'font-s-14', 'm-t-16'].join(' ')}>
        If there was any manual time spent outside of the App or any time that was missed (ie. the
        in-app timer was not running), please add the additional minutes spent below.
      </p>
      {renderTimeLogs()}
      <AddButton
        id="add-additional-entry"
        classNames={[styles.button]}
        label="Add additional entry"
        onClick={() => onTimeLogChange()}
        disabled={!_.isEmpty(timeLogs) && !!timeLogs.find((tl) => !tl.category || !tl.timeSpent)}
      />
    </>
  );
};

ManualTimeAddition.propTypes = {
  timeLogs: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      timeSpent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  onTimeLogChange: PropTypes.func,
  errors: PropTypes.object,
};

export default ManualTimeAddition;
