import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button } from 'common-src/components/base';
import Patient from 'common-src/models/Patient';
import {
  getPatientSmartGoals,
  getPatientSmartGoalsRequest,
} from 'common-src/models/PatientSmartGoal';
import { getPatientWellnessPlansRequest } from 'common-src/models/PatientWellnessPlan';
import { Colors, ColorsNew } from 'common-src/styles';

import { RequestHandlerScreen } from 'src/components/base';
import { ModalType } from 'src/components/base/ModalGroup';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { DefaultTabInfo, SmartGoalTabs } from './constants';
import { getTabInfo } from './helpers';
import styles from './SmartGoals.module.scss';
import SmartGoalsList from './SmartGoalsList';
import WellnessPlan from './WellnessPlan';

const SmartGoals = ({ patient, actionsAllowed }) => {
  const dispatch = useDispatch();

  const tabs = Object.keys(SmartGoalTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [filteredSmartGoals, setFilteredSmartGoals] = useState([]);
  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);

  const patientSmartGoals = useCustomSelector((state) => getPatientSmartGoals(state, patient.id));

  const { isRequesting } = useRequestLoading([
    getPatientSmartGoalsRequest({ patientId: patient.id }),
    getPatientWellnessPlansRequest({ patientId: patient.id }),
  ]);

  useInterval(() => {
    dispatch(
      getPatientSmartGoalsRequest({ patientId: patient.id, sort_order: 'desc', limit: 5 }, {}),
    );
    dispatch(getPatientWellnessPlansRequest({ patientId: patient.id }));
  });

  useEffect(() => {
    const { tabInfo, activeGoals, inactiveGoals } = getTabInfo(patientSmartGoals);
    setTabInfo(tabInfo);
    setFilteredSmartGoals(selectedTab === tabs[0] ? activeGoals : inactiveGoals);
  }, [selectedTab, patientSmartGoals]);

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling goals..." />;
  }

  return (
    <article className="relative">
      <div className={styles.scroll}>
        <WellnessPlan patient={patient} />
        <Button
          id="add-goal-button"
          classNames={[styles.button]}
          text="Add Goal"
          onClick={() => dispatch(openModal(ModalType.SMART_GOAL, { patientId: patient.id }))}
          backgroundColor={Colors.white}
          textColor={ColorsNew.darkGreen}
          iconSrc={CommonIcons.plusIcon}
          disabled={!actionsAllowed}
        />
        <TabsViewNew
          classNames={[styles.tabs, 'gap-30']}
          options={tabInfo}
          onTabSelected={setSelectedTab}
        />
        <SmartGoalsList
          patient={patient}
          smartGoals={filteredSmartGoals}
          editHandler={(smartGoalId) =>
            dispatch(openModal(ModalType.SMART_GOAL, { patientId: patient.id, smartGoalId }))
          }
          actionsAllowed={actionsAllowed}
        />
      </div>
    </article>
  );
};

SmartGoals.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  actionsAllowed: PropTypes.bool,
};

export default SmartGoals;
